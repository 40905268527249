import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';
import SelectAsync from 'react-select/async';
import { NavLink } from 'react-router-dom';
//https://s-yadav.github.io/react-number-format/docs/numeric_format/
import NumberFormat from 'react-number-format';

export default class Invoice extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'invoice',
            id: props.Id,
            isNew: props.IsNew,
            ins: props.Ins,
            inp: props.Inp,
            data: null,
            loading: true,
            bodyVersion: '',
            paymentVeriosn: '',
            t_am: 0,
            t_nw: 0,
            t_fee: 0,
            t_prdis: 0,
            t_dis: 0,
            t_adis: 0,
            t_vam: 0,
            t_tsstam: 0,
            t_pv: 0
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/invoice/get", {
            id: this.getCurrentFormId(),
            ins: this.state.ins,
            inp: this.state.inp,
            isNew: this.state.isNew
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                window.companyId = data.global.company.id;

                for (var i = 0; i < data.bodies.length; i++) { 
                    this.state.t_am += data.bodies[i]["am"];
                    this.state.t_nw += data.bodies[i]["nw"];
                    this.state.t_fee += data.bodies[i]["fee"];
                    this.state.t_prdis += data.bodies[i]["prdis"];
                    this.state.t_dis += data.bodies[i]["dis"];
                    this.state.t_adis += data.bodies[i]["adis"];
                    this.state.t_vam += data.bodies[i]["vam"];
                    this.state.t_tsstam += data.bodies[i]["tsstam"];
                }

                for (var i = 0; i < data.payments.length; i++) {
                    this.state.t_pv += data.payments[i]["pv"];
                }

                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    save(e) {
        e.preventDefault();
        this.httpPost("api/v1/invoice/save", {
            id: this.state.data.record.id,
            taxId: this.state.data.record.taxId,
            companyId: this.state.data.global.company.id,
            customerId: this.state.data.record.customerId,
            inno: this.state.data.record.inno,
            factorNo: this.isBlank(this.state.data.record.factorNo) ? 0 : this.state.data.record.factorNo,
            indatimPersian: this.state.data.record.indatimPersian,
            inty: this.state.data.record.inty,
            ins: this.state.data.record.ins,
            inp: this.state.data.record.inp,
            irtaxId: this.state.data.record.irtaxId,
            scln: this.state.data.record.scln,
            scc: this.state.data.record.scc,
            billid: this.state.data.record.billid,
            cdcn: this.state.data.record.cdcn,
            cdcdPersian: this.state.data.record.cdcdPersian,
            consfee: this.state.data.record.consfee,
            spro: this.state.data.record.spro,
            bros: this.state.data.record.bros,
            tcpbs: this.state.data.record.tcpbs,
            crn: this.state.data.record.crn,
            ft: this.state.data.record.ft,
            sendToCustomer: this.state.data.record.sendToCustomer,
            sendToTax: this.state.data.record.sendToTax, 
            
            bodies: this.state.data.bodies,
            payments: this.state.data.payments
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                this.setState({
                    redirect: '/invoices'
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    setBodyValue(id, field, value) {
        this.state.t_am = 0;
        this.state.t_nw = 0;
        this.state.t_fee = 0;
        this.state.t_prdis = 0;
        this.state.t_dis = 0;
        this.state.t_adis = 0;
        this.state.t_vam = 0;
        this.state.t_tsstam = 0;

        for (var i = 0; i < this.state.data.bodies.length; i++) {
            if (this.state.data.bodies[i].id === id) {
                if (field == 'stuffId') {
                    this.state.data.bodies[i][field] = value.value;
                }
                else {
                    this.state.data.bodies[i][field] = value;
                }

				if (this.isBlank(this.state.data.bodies[i]["am"])) this.state.data.bodies[i]["am"] = 1;
				if (this.isBlank(this.state.data.bodies[i]["nw"])) this.state.data.bodies[i]["nw"] = 0;
				if (this.isBlank(this.state.data.bodies[i]["fee"])) this.state.data.bodies[i]["fee"] = 0;
				if (this.isBlank(this.state.data.bodies[i]["dis"])) this.state.data.bodies[i]["dis"] = 0;
				if (this.isBlank(this.state.data.bodies[i]["vam"])) this.state.data.bodies[i]["vam"] = 0;
				if (this.isBlank(this.state.data.bodies[i]["tsstam"])) this.state.data.bodies[i]["tsstam"] = 0;

				if (field == 'stuffId') {
					if (value != null) this.state.data.bodies[i]["unitId"] = value.unitId;
					if (value != null) this.state.data.bodies[i]["fee"] = value.price;
					if (value != null) this.state.data.bodies[i]["vra"] = value.vra;
				}

                this.state.data.bodies[i]["prdis"] = this.FixedPointNumber(this.state.data.bodies[i]["fee"] * this.state.data.bodies[i]["am"]);
                this.state.data.bodies[i]["adis"] = this.FixedPointNumber(this.state.data.bodies[i]["prdis"] - this.state.data.bodies[i]["dis"]);

				if (this.state.data.bodies[i]["vra"] > 0)
                    this.state.data.bodies[i]["vam"] = this.FixedPointNumber(parseInt((this.state.data.bodies[i]["adis"] * this.state.data.bodies[i]["vra"]) / 100));
				else
					this.state.data.bodies[i]["vam"] = 0;

                this.state.data.bodies[i]["tsstam"] = this.FixedPointNumber(this.state.data.bodies[i]["adis"] + this.state.data.bodies[i]["vam"]);

			}
			
            this.state.t_am = this.FixedPointNumber(this.state.data.bodies[i]["am"] + this.state.t_am);
            this.state.t_nw = this.FixedPointNumber(this.state.data.bodies[i]["nw"] + this.state.t_nw);
            this.state.t_fee = this.FixedPointNumber(this.state.data.bodies[i]["fee"] + this.state.t_fee);
            this.state.t_prdis = this.FixedPointNumber(this.state.data.bodies[i]["prdis"] + this.state.t_prdis);
            this.state.t_dis = this.FixedPointNumber(this.state.data.bodies[i]["dis"] + this.state.t_dis);
            this.state.t_adis = this.FixedPointNumber(this.state.data.bodies[i]["adis"] + this.state.t_adis);
            this.state.t_vam = this.FixedPointNumber(this.state.data.bodies[i]["vam"] + this.state.t_vam);
            this.state.t_tsstam = this.FixedPointNumber(this.state.data.bodies[i]["tsstam"] + this.state.t_tsstam);
            console.log(this.state.data.bodies[i]["fee"]);
        }
        this.setState({ bodyVersion: this.uuidv4() });
    }
    setPaymentValue(id, field, value) {
        this.state.t_pv = 0;
        for (var i = 0; i < this.state.data.payments.length; i++) {
            if (this.state.data.payments[i].id === id) this.state.data.payments[i][field] = value;

            if (this.isBlank(this.state.data.payments[i]["pv"])) this.state.data.payments[i]["pv"] = 0;

            this.state.t_pv += this.state.data.payments[i]["pv"];
        }
        this.setState({ paymentVeriosn: this.uuidv4() });
    }
    newBodyRow(e) {
        e.preventDefault();
        this.state.data.bodies.push({
            id: this.uuidv4(),
            stuffId: 0,
            unitId: 0,
            am: 1,
            nw: 0,
            fee: 0,
            vra: 0,
            dis: 0,
            prdis: 0,
            adis: 0,
            vam: 0,
            tsstam: 0,

            cfee: 0,
            cut: 'IRR',
            exr: 1,
            ssrv: 0,
            sscv: 0,
            odt: '',
            odr: 0,
            odam: 0,
            consfee: 0,
            spro: 0,
            bros: 0,
            tcpbs: 0
            
        });
        this.setState({ data: this.state.data });
    }
    newPaymentRow(e) {
        e.preventDefault();
        this.state.data.payments.push({
            id: this.uuidv4(),
            pmt: 0,
            pid: '',
            pcn: '',
            iinn: '',
            acn: '',
            trmn: '',
            trn: '',
            pdtPersian: this.state.data.now_persian,
            pv: 0
        });
        this.setState({ data: this.state.data });
    }
    removeBodyRow(e, id) {
        e.preventDefault();
        var temp = [];
        this.state.data.bodies.forEach(r => {
            if (r.id != id) temp.push(r);
        });
        this.state.data.bodies = temp;
        this.setState({ data: this.state.data });
    }
    removePaymentRow(e, id) {
        e.preventDefault();
        var temp = [];
        this.state.data.payments.forEach(r => {
            if (r.id != id) temp.push(r);
        });
        this.state.data.payments = temp;
        this.setState({ data: this.state.data });
    }
    loadPersonsOptions(inputValue, callback) {
        var companyId = window.companyId;
        setTimeout(() => {
            fetch("/api/v1/common/getpersons/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                    companyId,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(response => {
                let items = [];
                if (response.status == 1) {
                    response.records.map(_record => items.push({
                        value: _record.id,
                        label: _record.name
                    }));
                }
                callback(items);
            })
        }, 50);
    };
    loadStuffsOptions(inputValue, callback) {
        var companyId = window.companyId;
        setTimeout(() => {
            fetch("/api/v1/common/getstuffs/", {
                method: "post",
                body: JSON.stringify({
                    "term": inputValue,
                    companyId,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(response => {
                let items = [];
                if (response.status == 1) {
                    response.records.map(_record => items.push({
                        value: _record.id,
                        label: _record.name + " " + _record.taxNumber,
                        price: _record.price,
                        unitId: _record.unitId,
                        vra: _record.taxRate
                    }));
                }
                callback(items);
            })
        }, 50);
    }
    getBodyColumnCount() {
        if (this.state.data.record.inp === 1) return 11;
        if (this.state.data.record.inp === 2) return 14;
        if (this.state.data.record.inp === 3) return 15;
        if (this.state.data.record.inp === 4) return 10;
        if (this.state.data.record.inp === 5) return 13;
        if (this.state.data.record.inp === 6) return 11;
        if (this.state.data.record.inp === 7) return 13;
        return 0;
    }
    shaneSendToTax(checked) {
        this.state.data.record.sendToTax = checked;
        this.setState({ data: this.state.data });
    }
    renderForm() {
        return (
            <form onSubmit={e => this.save(e)} className="row g-4">
                <div className="nk-block-head">
                    <div className="nk-block-between g-3">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">الگوی صورتحساب: <strong className="text-primary small">{this.state.data.record.inpString}</strong></h3>
                        </div>
                        <div className="nk-block-head-content">
                            <NavLink to="/invoices/" className="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em className="icon ni ni-arrow-left"></em><span>برگشت</span></NavLink>
                            <NavLink to="/invoices" className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em className="icon ni ni-arrow-left"></em></NavLink>
                            {this.state.data.record.id > 0 && <a onClick={e => this.getPdf(e)} className="btn btn-white btn-outline-light"><em class="icon ni ni-file-pdf"></em><span>خروجی PDF</span></a>}
                        </div>
                    </div>
                </div>
                {this.state.data.record.status === 2 && 
                    <div className="example-alert">
                        <div className="alert alert-pro alert-warning">
                            <div className="alert-text">
                                <h6>توجه</h6>
                                <p>این صورتحساب در حال حاضر در لیست انتظار سامانه مودیان قرار دارد و هنوز تایید/رد نشده است</p>
                            </div>
                        </div>
                    </div>
                }
                {this.state.data.record.status === 3 &&
                    <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                                <h6>نتایج استعلام صورتحساب از سامانه مودیان</h6>
                                <p>این صورتحساب به علت وجود خطاهای زیر توسط سامان مورد پذیرش قرار نگرفت. لطفا خطاهای زیر را بررسی و اصلاح نموده و مجدد صورتحساب را ارسال نمایید</p>                        <ul>
                                    {this.state.data.erros.map(record =>
                                        <li>
                                            {record.msg}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {this.state.data.record.status === 4 &&
                    <div className="example-alert">
                        <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                                <h6>تبریک</h6>
                                <p>این صورتحساب با موفقیت در کارپوشه سامانه مودیان ثبت شده است</p>
                            </div>
                        </div>
                    </div>
                }

                <div className="card card-bordered card-preview">
                    <div className="card-inner">
                        <ul className="nav nav-tabs mt-n3">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#header"><em className="icon ni ni-info"></em><span>اطلاعات صورتحساب</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#bodies"><em className="icon ni ni-list-round"></em><span>اقلام صورتحساب</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#payments"><em className="icon ni ni-wallet-alt"></em><span>پرداختها</span></a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            {(this.state.data.record.status == 1 || this.state.data.record.status == 4) && <div className="form-control-wrap">
                                                <input type="checkbox" defaultChecked={this.state.data.record.sendToTax} onChange={e => this.shaneSendToTax(e.target.checked)} style={{ marginLeft: "5px" }} />
                                                این صورتحساب قبلا توسط سیستم دیگری به سامانه مودیان ارسال شده است و دارای شماره منحصر بفرد مالیاتی می باشد
                                            </div>}
                                            {this.state.data.record.sendToTax == false && <div className="form-control-wrap">
                                                <input type="checkbox" defaultChecked={this.state.data.record.sendToCustomer} onChange={e => this.state.data.record.sendToCustomer = e.target.checked} style={{ marginLeft: "5px" }} />
                                                تا قبل از نهایی شدن فاکتور خریدار میتواند این فاکتور را مشاهده نماید
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="container" id="invoiceCustomerBox">
                                            <div className="form-group">
                                                <label className="form-label">طرف حساب</label>
                                                <div className="form-control-wrap">
                                                    <SelectAsync
                                                        defaultValue={this.state.data.record.customerId >0 ? [{ value: this.state.data.record.customerId, label: this.state.data.record.customerName }] : null}
                                                        isMulti={false}
                                                        placeholder="انتخاب طرف حساب"
                                                        defaultOptions
                                                        loadOptions={this.loadPersonsOptions}
                                                        onChange={e => this.state.data.record.customerId = e.value}
                                                    />
                                                </div>
                                            </div>

                                            {this.state.data.record.inp === 2 && <div className="form-group">
                                                <label className="form-label">شماره پروانه گمرکی</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center" type="text" defaultValue={this.state.data.record.scln} onChange={e => this.state.data.record.scln = e.target.value} />
                                                </div>
                                            </div>}
                                            {this.state.data.record.inp === 2 && <div className="form-group">
                                                <label className="form-label">کد گمرک محل اظهار</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center" type="text" defaultValue={this.state.data.record.scc} onChange={e => this.state.data.record.scc = e.target.value} />
                                                </div>
                                            </div>}

                                            {this.state.data.record.inp === 4 && <div className="form-group">
                                                <label className="form-label">شناسه یکتای ثبت قرارداد فروشنده</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center" type="text" defaultValue={this.state.data.record.crn} onChange={e => this.state.data.record.crn = e.target.value} />
                                                </div>
                                            </div>}

                                            {this.state.data.record.inp === 5 && <div className="form-group">
                                                <label className="form-label">شماره اشتراک / شناسه قبص بهره بردار</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center" type="text" defaultValue={this.state.data.record.billid} onChange={e => this.state.data.record.billid = e.target.value} />
                                                </div>
                                            </div>}

                                            {this.state.data.record.inp === 6 && <div className="form-group">
                                                <label className="form-label">نوع پرواز</label>
                                                <div className="form-control-wrap">
                                                    <select className="form-control" type="text" defaultValue={this.state.data.record.ft} onChange={e => this.state.data.record.ft = parseInt(e.target.value)}>
                                                        <option value='0'>انتخاب نمایید...</option>
                                                        <option value="1">داخلی</option>
                                                        <option value="2">خارجی</option>
                                                    </select>
                                                </div>
                                            </div>}

                                            {this.state.data.record.inp === 7 && <div className="form-group">
                                                <label className="form-label">شماره کوتاژ اظهارنامه گمرکی</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center" type="text" defaultValue={this.state.data.record.cdcn} onChange={e => this.state.data.record.cdcn = e.target.value} />
                                                </div>
                                            </div>}
                                            {this.state.data.record.inp === 7 && <div className="form-group">
                                                <label className="form-label">تاریخ کوتاژ اظهارنامه گمرکی</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center" type="text" defaultValue={this.state.data.record.cdcdPersian} onChange={e => this.state.data.record.cdcdPersian = e.target.value} />
                                                </div>
                                            </div>}
                                            {this.state.data.record.sendToTax &&
                                                <div className="form-group">
                                                    <label className="form-label">شماره منحصربفرد</label>
                                                    <div className="form-control-wrap">
                                                        <input maxLength="22" className="form-control text-center" type="text" defaultValue={this.state.data.record.taxId} onChange={e => this.state.data.record.taxId = e.target.value} />
                                                    </div>
                                                </div>
                                            }
                                            {this.state.data.record.ins !== 1  && <div className="form-group">
                                                <label className="form-label">شماره منحصربفرد مرجع</label>
                                                <div className="form-control-wrap">
                                                    <input maxLength="22" className="form-control text-center" type="text" defaultValue={this.state.data.record.irtaxId} onChange={e => this.state.data.record.irtaxId = e.target.value} />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="container" id="invoiceDateBox">
                                            <div className="form-group">
                                                <label className="form-label">نوع صورتحساب</label>
                                                <div className="form-control-wrap">
                                                    <select className="form-control" type="text" defaultValue={this.state.data.record.inty} onChange={e => this.state.data.record.inty = parseInt(e.target.value)}>
                                                        <option value='0'>انتخاب نمایید...</option>
                                                        <option value="1">نوع اول (فروش به مودیان مالیاتی) </option>
                                                        <option value="2">نوع دوم (فروش به مصرف کننده نهایی)</option>
                                                        <option value="3">نوع سوم (فروش با کارتخوان یا پایانه فروشگاهی)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">تاریخ صورتحساب</label>
                                                <div className="form-control-wrap">
                                                    <input className="form-control text-center ltr" type="text" defaultValue={this.state.data.record.indatimPersian} onChange={e => this.state.data.record.indatimPersian = e.target.value} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">سریال</label>
                                                <div className="form-control-wrap">
                                                    <NumberFormat value={this.state.data.record.inno} onChange={e => this.state.data.record.inno = parseInt(e.target.value.replace(/,/g, ''))} thousandSeparator={false} prefix={''} className='form-control text-center ltr' />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">شماره فاکتور (اختیاری)</label>
                                                <div className="form-control-wrap">
                                                    <NumberFormat value={this.state.data.record.factorNo} onChange={e => this.state.data.record.factorNo = parseInt(e.target.value.replace(/,/g, ''))} thousandSeparator={false} prefix={''} className='form-control text-center ltr' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="bodies">
                                <table className="table table-ulogs invoceEntryTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center"><span className="overline-title">کالا / خدمات</span></th>
                                            <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">واحد</span></th>
                                            <th className="text-center" style={{ "width": "60px" }}><span className="overline-title">تعداد/مقدار</span></th>
                                            {this.state.data.record.inp === 7 && <th className="text-center" style={{ "width": "55px" }}><span className="overline-title">وزن خالص</span></th>}
                                            <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ واحد</span></th>

                                            {this.state.data.record.inp === 2 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">میزان ارز</span></th>}

                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">نوع ارز</span></th>}
                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">نرخ برابری ارز و ریال</span></th>}

                                            {this.state.data.record.inp === 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">ارزش ریالی</span></th>}
                                            {this.state.data.record.inp === 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">ارزش ارزی</span></th>}

                                            {this.state.data.record.inp !== 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ قبل تخفیف</span></th>}
                                            {this.state.data.record.inp !== 7 && <th className="text-center" style={{ "width": "70px" }}><span className="overline-title">تخفیف</span></th>}
                                            {this.state.data.record.inp !== 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ بعد تخفیف</span></th>}

                                            <th className="text-center" style={{ "width": "55px" }}><span className="overline-title">نرخ مالیات</span></th>
                                            <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مالیات</span></th>

                                            {this.state.data.record.inp === 5 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">موضوع سایر مالیات</span></th>}
                                            {this.state.data.record.inp === 5 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">نرخ سایر مالیات</span></th>}
                                            {this.state.data.record.inp === 5 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ سایر مالیات</span></th>}

                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">اجرت ساخت</span></th>}
                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">سود فروشنده</span></th>}
                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">حق العمل</span></th>}
                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">جمع کل اجرت، حق العمل و سود</span></th>}

                                            <th className="text-center" style={{ "width": "90px" }}><span className="overline-title">جمع کل</span></th>
                                            <th className="tb-col-action"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.bodies.map(record =>
                                            <tr key={record.id}>
                                                <td>
                                                    <SelectAsync
                                                        defaultValue={record.stuffId > 0 ? [{ value: record.stuffId, label: record.stuffName + " " + record.stuffTaxNumber }] : null}
                                                        isMulti={false}
                                                        placeholder="انتخاب کالا/خدمت"
                                                        defaultOptions
                                                        loadOptions={this.loadStuffsOptions}
                                                        onChange={e => this.setBodyValue(record.id, 'stuffId', e)}
                                                    />
                                                </td>
                                                <td>
                                                    <select style={{ "width": "80px" }} className="form-control" type="text" key={record.unitId} defaultValue={record.unitId} onChange={e => this.setBodyValue(record.id, 'unitId', parseInt(e.target.value))}>
                                                        <option value='0'>انتخاب نمایید...</option>
                                                        {this.state.data.units.map(record =>
                                                            <option value={record.id}>{record.name}</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td>
                                                    <NumberFormat style={{ "width": "60px" }} defaultValue={record.am} onChange={e => this.setBodyValue(record.id, 'am', (this.isBlank(e.target.value.replace(/,/g, '')) ? 0 : parseFloat(e.target.value.replace(/,/g, ''))))} thousandSeparator={true} prefix={''} className='form-control' />
                                                </td>
                                                {this.state.data.record.inp === 7 && <td>
                                                    <NumberFormat style={{ "width": "60px" }} defaultValue={record.nw} onChange={e => this.setBodyValue(record.id, 'nw', (this.isBlank(e.target.value.replace(/,/g, '')) ? 0 : parseFloat(e.target.value.replace(/,/g, ''))))} thousandSeparator={true} prefix={''} className='form-control' />
                                                </td>}
                                                <td>
                                                    <NumberFormat style={{ "width": "80px" }} value={record.fee} onChange={e => this.setBodyValue(record.id, 'fee', (this.isBlank(e.target.value.replace(/,/g, '')) ? 0 : parseInt(e.target.value.replace(/,/g, ''))))} thousandSeparator={true} prefix={''} className='form-control' />
                                                </td>
                                                
                                                {this.state.data.record.inp === 2 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.cfee} onChange={e => this.setBodyValue(record.id, 'cfee', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td>
                                                    <select style={{ "width": "80px" }} className="form-control" type="text" key={record.cut} defaultValue={record.cut} onChange={e => this.setBodyValue(record.id, 'cut', e.target.value)}>
                                                        <option value='0'>انتخاب نمایید...</option>
                                                        {this.state.data.currencies.map(record =>
                                                            <option value={record.alphabeticCode}>{record.alphabeticCode}</option>
                                                        )}
                                                    </select>
                                                </td>}
                                                {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.exr} onChange={e => this.setBodyValue(record.id, 'exr', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {this.state.data.record.inp === 7 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.ssrv} onChange={e => this.setBodyValue(record.id, 'ssrv', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {this.state.data.record.inp === 7 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.sscv} onChange={e => this.setBodyValue(record.id, 'sscv', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}

                                                {this.state.data.record.inp !== 7 && <td><label className="text-center" style={{ "width": "80px" }} key={record.prdis}>{this.money(record.prdis)}</label></td>}
                                                {this.state.data.record.inp !== 7 && <td>
                                                    <NumberFormat style={{ "width": "70px" }} defaultValue={record.dis} onChange={e => this.setBodyValue(record.id, 'dis', parseInt(e.target.value.replace(/,/g, '')))} thousandSeparator={true} prefix={''} className='form-control' />
                                                </td>}
                                                {this.state.data.record.inp !== 7 && <td><label className="text-center" style={{ "width": "80px" }} key={record.adis}>{this.money(record.adis)}</label></td>}

                                                <td><label className="text-center" style={{ "width": "50px" }} key={record.vra}>{record.vra}</label></td>
                                                <td><label className="text-center" style={{ "width": "80px" }} key={record.vam}>{this.money(record.vam)}</label></td>

                                                {this.state.data.record.inp === 5 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.odt} onChange={e => this.setBodyValue(record.id, 'odt', e.target.value)} /></td>}
                                                {this.state.data.record.inp === 5 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.odr} onChange={e => this.setBodyValue(record.id, 'odr', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {this.state.data.record.inp === 5 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.odam} onChange={e => this.setBodyValue(record.id, 'odam', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}

                                                {this.state.data.record.inp === 3 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.consfee} onChange={e => this.setBodyValue(record.id, 'consfee', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {this.state.data.record.inp === 3 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.spro} onChange={e => this.setBodyValue(record.id, 'spro', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {this.state.data.record.inp === 3 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.bros} onChange={e => this.setBodyValue(record.id, 'bros', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                {this.state.data.record.inp === 3 && <td><input style={{ "width": "70px" }} className="form-control" type="text" value={record.tcpbs} onChange={e => this.setBodyValue(record.id, 'tcpbs', (this.isBlank(e.target.value) ? 0 : parseInt(e.target.value)))} /></td>}
                                                
                                                <td><label className="text-center" style={{ "width": "90px" }} key={record.tsstam}>{this.money(record.tsstam)}</label></td>
                                                <td className="tb-col-action"><a onClick={e => this.removeBodyRow(e, record.id)} className="link-cross me-sm-n1 hand"><em className="icon ni ni-cross"></em></a></td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ "background-color": "#e9e8e1" }}>
                                            <td colSpan="2" className="text-center">
                                                <b>مجموع آیتم ها</b>
                                            </td>
                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_am)}</labe>
                                            </td>
                                            {this.state.data.record.inp === 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_nw)}</labe>
                                            </td>}
                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_fee)}</labe>
                                            </td>

                                            {this.state.data.record.inp === 2 && <td></td>}
                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td></td>}
                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td></td>}
                                            {this.state.data.record.inp === 7 && <td></td>}
                                            {this.state.data.record.inp === 7 && <td></td>}

                                            {this.state.data.record.inp !== 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_prdis)}</labe>
                                            </td>}
                                            {this.state.data.record.inp !== 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_dis)}</labe>
                                            </td>}
                                            {this.state.data.record.inp !== 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_adis)}</labe>
                                            </td>}
                                            <td className="text-center"></td>
                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_vam)}</labe>
                                            </td>

                                            {this.state.data.record.inp === 5 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 5 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 5 && <td className="text-center"></td>}

                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}

                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_tsstam)}</labe>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={this.getBodyColumnCount() }>
                                                <a className="btn btn-dim btn-round btn-sm btn-primary hand" onClick={e => this.newBodyRow(e)}><em className="icon ni ni-grid-add-c"></em> <span>افزودن سطر جدید</span></a>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className="tab-pane" id="payments">
                                <table className="table table-ulogs invoceEntryTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center"><span className="overline-title">نوع پرداخت</span></th>
                                            <th style={{ "width": "120px" }} className="text-center"><span className="overline-title">کدملی پرداخت کنننده</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">شماره کارت</span></th>
                                            <th style={{ "width": "140px" }} className="text-center"><span className="overline-title">تاریخ و زمان</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">مبلغ</span></th>
                                            <th style={{ "width": "120px" }} className="text-center"><span className="overline-title">شماره سوییچ پرداخت</span></th>
                                            <th style={{ "width": "120px" }} className="text-center"><span className="overline-title">شماره پذیرنده فروشگاهی</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">شماره پایانه</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">شماره پیگیری</span></th>
                                            <th className="tb-col-action"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.payments.map(record =>
                                            <tr key={record.id}>
                                                <td>
                                                    <select style={{ "width": "100%" }} className="form-control" type="text" defaultValue={record.pmt} onChange={e => this.setPaymentValue(record.id, 'pmt', parseInt(e.target.value))}>
                                                        <option value='0'>انتخاب کنید ...</option>
                                                        <option value='1'>چک</option>
                                                        <option value='2'>تهاتر</option>
                                                        <option value='3'>وجه نقد</option>
                                                        <option value='4'>pos</option>
                                                        <option value='5'>درگاه اینترنتی</option>
                                                        <option value='6'>کارت به کارت</option>
                                                        <option value='7'>انتقال به حساب</option>
                                                        <option value='8'>سایر موارد</option>
                                                    </select>
                                                </td>
                                                <td><input style={{ "width": "120px" }} className="form-control" type="text" defaultValue={record.pid} onChange={e => this.setPaymentValue(record.id, 'pid', e.target.value)} /></td>
                                                <td><input style={{ "width": "80px" }} className="form-control" type="text" defaultValue={record.pcn} onChange={e => this.setPaymentValue(record.id, 'pcn', e.target.value)} /></td>
                                                <td><input style={{ "width": "140px" }} className="form-control ltr" type="text" defaultValue={record.pdtPersian} onChange={e => this.setPaymentValue(record.id, 'pdtPersian', e.target.value)} /></td>
                                                <td><NumberFormat style={{ "width": "80px" }} defaultValue={record.pv} onChange={e => this.setPaymentValue(record.id, 'pv', (this.isBlank(e.target.value.replace(/,/g, '')) ? 0 : parseInt(e.target.value.replace(/,/g, ''))))} thousandSeparator={true} prefix={''} className='form-control' /></td>
                                                <td><input style={{ "width": "120px" }} className="form-control" type="text" defaultValue={record.iinn} onChange={e => this.setPaymentValue(record.id, 'iinn', e.target.value)} /></td>
                                                <td><input style={{ "width": "120px" }} className="form-control" type="text" defaultValue={record.acn} onChange={e => this.setPaymentValue(record.id, 'acn', e.target.value)} /></td>
                                                <td><input style={{ "width": "80px" }} className="form-control" type="text" defaultValue={record.trmn} onChange={e => this.setPaymentValue(record.id, 'trmn', e.target.value)} /></td>
                                                <td><input style={{ "width": "80px" }} className="form-control" type="text" defaultValue={record.trn} onChange={e => this.setPaymentValue(record.id, 'trn', e.target.value)} /></td>
                                                <td className="tb-col-action"><a onClick={e => this.removePaymentRow(e, record.id)} className="link-cross me-sm-n1 hand"><em className="icon ni ni-cross"></em></a></td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ "background-color": "#e9e8e1" }}>
                                            <td colSpan="4" className="text-center">
                                                <b>مجموع آیتم ها</b>
                                            </td>
                                            <td className="text-center" key={this.state.t_pv}>{this.money(this.state.t_pv)}</td>
                                            <td colSpan="4" className="text-center"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <a className="btn btn-dim btn-round btn-sm btn-primary hand" onClick={e => this.newPaymentRow(e)}><em className="icon ni ni-grid-add-c"></em> <span>افزودن سطر جدید</span></a>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <button type="submit" className="btn btn-primary"><em className="icon ni ni-save"></em> ذخیره</button>
                </div>
            </form>
        );
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}