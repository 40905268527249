import React from 'react';
import Base from './Base'

export class Footer extends Base {
  constructor (props) {
    super(props);
   }

  render() {
      return   <div className="nk-footer">
                            <div className="container-fluid">
                                <div className="nk-footer-wrap">
                                    <div className="nk-footer-copyright">
                                        تمامی حقوق این سایت متعلق به ایده آل پردازش دایا است.
                                    </div>
                                </div>
                            </div>
                        </div>
  }
}
