import React from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import Base from './Base'

export class NavMenu extends Base {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            menuOpen: 1
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/common/get")
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    this.setState({
                        data: data,
                        loading: false
                    })
                }
                else {
                    this.showErorrMessage(data.message);
                    this.setState({
                        loading: false
                    });
                }
            });
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : this.renderForm();

        return contents;
    }
    toggleMenu() {
        if (this.state.menuOpen == 1) this.setState({ menuOpen: 0 });
        else this.setState({ menuOpen: 1 });
    }
    renderForm() {
        return <div className={"nk-sidebar nk-sidebar-fixed is-dark " + (this.state.menuOpen == 1 ? "":"is-compact")} id="sideBarMenu" data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head">
                        <div className="nk-menu-trigger" onClick={e => this.toggleMenu() }>
                          <a className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
                          <a className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                      </div>
                      </div>
                    <div className="nk-sidebar-element nk-sidebar-body">
                        <div className="nk-sidebar-content">
                            <div className="nk-sidebar-menu" data-simplebar>
                                <ul className="nk-menu">
                                    <li className="nk-menu-item">
                                        <Link to="/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-home"></em></span>
                                            <span className="nk-menu-text">داشبرد</span>
                                        </Link>
                                    </li>
                            {(this.state.data.global.user.role === 3 || this.state.data.global.user.role === 1) && <li className="nk-menu-heading">
                              <h6 className="overline-title text-primary-alt">مدیریتی</h6>
                          </li>}
                            {(this.state.data.global.user.role === 3 || this.state.data.global.user.role === 1) && <li className="nk-menu-item">
                                        <Link to="/users/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                            <span className="nk-menu-text">کاربران</span>
                                        </Link>
                                    </li>}
                                    {this.state.data.global.user.role === 1 && <li className="nk-menu-item">
                                        <Link to="/units/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-coins"></em></span>
                                            <span className="nk-menu-text">واحدهای اندازه گیری</span>
                                        </Link>
                                    </li>}
                                    {this.state.data.global.user.role === 1 && <li className="nk-menu-item">
                                        <Link to="/plans/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-box"></em></span>
                                            <span className="nk-menu-text">پلنها</span>
                                        </Link>
                                    </li>}
                          {this.state.data.global.user.role === 1 && <li className="nk-menu-item">
                              <Link to="/transactions/" className="nk-menu-link">
                                  <span className="nk-menu-icon"><em className="icon ni ni-coins"></em></span>
                                  <span className="nk-menu-text">پرداختها</span>
                              </Link>
                          </li>}
                                    <li className="nk-menu-heading">
                                        <h6 className="overline-title text-primary-alt">اطلاعات پایه</h6>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/companies/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-building"></em></span>
                                            <span className="nk-menu-text">شرکتهای من</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/stuffs/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-package-fill"></em></span>
                                            <span className="nk-menu-text">کالاها/خدمات</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/persons/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                            <span className="nk-menu-text">مشتریان</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-heading">
                                        <h6 className="overline-title text-primary-alt">حسابداری</h6>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/invoices/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-file-docs"></em></span>
                                            <span className="nk-menu-text">صورتحساب های فروش</span>
                                        </Link>
                                    </li>                          
                                    <li className="nk-menu-heading">
                                        <h6 className="overline-title text-primary-alt">ابزارها</h6>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/mytransactions/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-coins"></em></span>
                                            <span className="nk-menu-text">پرداختهای من</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/inquiry/economiccode/" className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-search"></em></span>
                                            <span className="nk-menu-text">استعلام شناسه ملی</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

  }
}
