import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Base from './Base'

export class Header extends Base {
  constructor (props) {
    super(props);
    this.state = {
        data: props.data
    }
    }
    toggleSideBar() {
        //document.getElementsByName('body').classList.add('nk-body bg-lighter npc-general has-sidebar has-rtl has-touch as-mobile nk-nio-theme nav-shown');
        if (document.getElementById('sideBarMenu').classList.contains("nk-sidebar-active"))
            document.getElementById('sideBarMenu').classList.remove('nk-sidebar-active');
        else
            document.getElementById('sideBarMenu').classList.add('nk-sidebar-active');
    }
  render() {
      return   <div className="nk-header nk-header-fixed is-light">
                            <div className="container-fluid">
                                <div className="nk-header-wrap">
                                    <div className="nk-menu-trigger d-xl-none ms-n1">
                                        <a href="#" onClick={e=>this.toggleSideBar()} className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                                    </div>
                                    <div className="nk-header-brand d-xl-none">
                                        <a href="/" className="logo-link">
                                              <img className="logo-light logo-img" src="/assets/images/logo.webp" alt="" />
                                                <img className="logo-dark logo-img" src="/assets/images/logo-dark.webp" alt="" />
                                        </a>
                                    </div>
                                            <div className="nk-header-news d-none d-xl-block">
                                                 {this.isEmpty(this.state.data.global.companies)?
                                                        <div className="nk-news-list">
                                                                <NavLink className="nk-news-item" to="/company/new/">
                                                                    <div className="nk-news-icon">
                                                                        <em className="icon ni ni-card-view"></em>
                                                                    </div>
                                                                    <div className="nk-news-text">
                                                                        <p>شما هنوز هیچ شرکتی ثبت نکرده اید برای شروع کلیک نمایید</p>
                                                                    </div>
                                                                </NavLink>
                                                        </div>
                                                : 
                                                  <ul className="nk-quick-nav">
                                                    <li className="dropdown user-dropdown">
                                                        { !this.isNull(this.state.data.global.company) && 
                                                            <Link to={"/company/edit/" + this.state.data.global.company.id + "/"} className="dropdown-toggle" data-bs-toggle="dropdown">
                                                                <div className="user-toggle">
                                                                    <div className="user-info d-none d-md-block">
                                                                        <div className="user-status">{this.state.data.global.company.name }</div>
                                                  <div className="user-name dropdown-indicator">{this.state.data.global.company.nationalId} - {this.state.data.global.company.taxUserName}</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        }
                                                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                                                          {this.state.data.global.companies.map(co =>
                                                              <a onClick={e => this.changeCompany(co.id)} className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block hand">
                                                                  <div className="user-card">
                                                                      <div className="user-info">
                                                                          <span className="lead-text">{co.name}</span>
                                                                          <span className="sub-text">{co.nationalId} - {co.taxUserName}</span>
                                                                      </div>
                                                                  </div>
                                                              </a>
                                                          )}
                                                        </div>
                                                    </li>
                                                </ul>
                                                }
                                            </div>
                                            <div className="nk-header-tools">
                                                <ul className="nk-quick-nav">
                                                    <li className="dropdown user-dropdown">
                                                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                                                            <div className="user-toggle">
                                                                <div className="user-avatar sm">
                                                                    <em className="icon ni ni-user-alt"></em>
                                                                </div>
                                                                <div className="user-info d-none d-md-block">
                                                                    <div className="user-status">{this.state.data.global.user.mobile}</div>
                                                                    <div className="user-name dropdown-indicator">{this.state.data.global.user.fname} {this.state.data.global.user.lname}</div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                                                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                                <div className="user-card">
                                                                    <div className="user-avatar">
                                                                        <span>{this.state.data.global.user.fname.substring(0,2)}</span>
                                                                    </div>
                                                                    <div className="user-info">
                                                                        <span className="lead-text">{this.state.data.global.user.fname} {this.state.data.global.user.lname}</span>
                                                                        <span className="sub-text">{this.state.data.global.user.mobile}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="dropdown-inner">
                                                                <ul className="link-list">
                                                                    <li><Link to="/profile/"><em className="icon ni ni-user-alt"></em><span>حساب کاربری</span></Link></li>
                                                                </ul>
                                                            </div>
                                                            <div className="dropdown-inner">
                                                                <ul className="link-list">
                                                                    <li><a className="hand" onClick={e => this.logOut() }><em className="icon ni ni-signout"></em><span>خروج</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown notification-dropdown me-n1">
                                                        <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-bs-toggle="dropdown">
                                                            <div className="icon-status icon-status-info"><em className="icon ni ni-bell"></em></div>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-xl dropdown-menu-end dropdown-menu-s1">
                                                            <div className="dropdown-head">
                                                                <span className="sub-title nk-dropdown-title">اطلاعیه ها</span>
                                                            </div>
                                                            <div className="dropdown-body">
                                                                <div className="nk-notification">
                                                                    {this.state.data.global.notifications.map(notif =>
                                                                        <div className="nk-notification-item dropdown-inner">
                                                                            <div className="nk-notification-icon">
                                                                                <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                                                                            </div>
                                                                            <div className="nk-notification-content">
                                                                                <div className="nk-notification-text">{notif.text }</div>
                                                                                <div className="nk-notification-time">{notif.date}</div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                    </div>
                                </div>
                            </div>
  }
}
