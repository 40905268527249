import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Invoices extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'invoice',
            data: null,
            loading: true,
            id_to_remove: null,
            page: 1,
            per_page: 10,
            phrase: '',
            ids: [],
            filters: {
                type: 0,
                inp: 0,
                ins: 0
            }
        };
    }
    componentDidMount() {
        this.refreshData();
    }
    enqueue(id) {
        this.sendEnqueue([id]);
    }
    sendEnqueue(ids) {
        this.httpPost("api/v1/invoice/enqueue", {
            ids: ids,
            companyId: this.state.data.global.company.id,
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("ارسال با موفقیت انجام پذیرفت گردید");
                this.refreshData();
            }
            else
                this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    reference(id) {
        this.httpPost("api/v1/invoice/reference", {
            id: id,
            companyId: this.state.data.global.company.id,
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("استعلام صورتحساب دریافت شد");
                this.setState({
                    redirect: "/invoice/edit/" + id
                })
            }
            else
                this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    getToolBars(record) {
        var m = [];
        m.push(<li><NavLink to={"/invoice/view/" + record.id} title="مشاهده"><em className="icon ni ni-eye"></em> <span>مشاهده</span></NavLink></li>);
        if (record.status === 1 || record.status === 3 || record.sendToTax) {
            m.push(<li><NavLink to={"/invoice/edit/" + record.id} title="ویرایش"><em className="icon ni ni-pen"></em> <span>ویرایش</span></NavLink></li>);
            m.push(<li><a className="hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove" title="حذف"><em className="icon ni ni-na"></em> <span>حذف صورتحساب</span></a></li>);
        }
        if (record.status === 1 || record.status === 3) {
            m.push(<li><a className="hand" onClick={e => this.enqueue(record.id)} title="ارسال به سامانه"><em class="icon ni ni-send"></em> <span>ارسال به سامانه</span></a></li>);
        }
        if (record.status === 2) {
            m.push(<li><a className="hand" onClick={e => this.reference(record.id)} title="استعلام"><em class="icon ni ni-search"></em> <span>استعلام صورتحساب</span></a></li>);
        }
        if (record.status === 4) {
            m.push(<li><NavLink to={"/invoice/cancell/" + record.id} title="صدور ابطالی"><em class="icon ni ni-property-remove"></em> <span>صدور ابطالی</span></NavLink></li>);
            m.push(<li><NavLink to={"/invoice/modify/" + record.id} title="صدور اصلاحی"><em class="icon ni ni-property-add"></em> <span>صدور اصلاحی</span></NavLink></li>);
            m.push(<li><NavLink to={"/invoice/return/" + record.id} title="صدور برگشت از فروش"><em class="icon ni ni-property-alt"></em> <span>صدور برگشت از فروش</span></NavLink></li>);
        }
        return m;
    }
    setChecked(e, id) {
        var t = [];
        this.state.Ids.forEach(i => {
            if (i != id) t.push(i);
        });
        if (e.target.checked) t.push(id);
        this.state.Ids = t;
    }
    renderForm() {
        return <div>
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">فهرست صورتحسابهای فروش</h3>
                            <div className="nk-block-des text-soft">
                                <p>شما {this.money(this.state.data.recordsCount)} صورتحساب ثبت شده دارید</p>
                            </div>
                        </div>
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <a onClick={e => this.getPdfs(e)} className="btn btn-white btn-outline-light"><em class="icon ni ni-file-pdf"></em><span>خروجی PDF اطلاعات</span></a>
                                        </li>
                                        <li>
                                            <NavLink to="/invoice/upload/" className="btn btn-white btn-outline-light"><em className="icon ni ni-download-cloud"></em><span>ورود اکسل اطلاعات</span></NavLink>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <div className="drodown">
                                                <a href="#" className="dropdown-toggle btn btn-icon btn-primary" data-bs-toggle="dropdown" aria-expanded="true"><em className="icon ni ni-plus"></em></a>
                                                <div className="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end">
                                                    <ul className="link-list-opt no-bdr">
                                                        <li><NavLink to="/invoice/new1/"><span>فروش</span></NavLink></li>
                                                        <li><NavLink to="/invoice/new2/"><span>فروش ارزی</span></NavLink></li>
                                                        <li><NavLink to="/invoice/new3/"><span>طلا و جواهر و پلاتین</span></NavLink></li>
                                                        <li><NavLink to="/invoice/new4/"><span>پیمانکاری</span></NavLink></li>
                                                        <li><NavLink to="/invoice/new5/"><span>قبوض خدماتی</span></NavLink></li>
                                                        <li><NavLink to="/invoice/new6/"><span>بلیت هواپیما</span></NavLink></li>
                                                        <li><NavLink to="/invoice/new7/"><span>صادرات</span></NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                        {this.state.data.recordsCount <= 0 ?
                            <div className="card-inner-group">
                                <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                        <div className="alert-text">
                                            <h6>توجه</h6>
                                            <p>در حال حاضر هیچ صورتحساب فروشی برای شرکت شما ثبت نشده است</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="card-inner-group">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                        <form className="card-tools" mthod="post" onSubmit={e => this.refreshData(e)}>
                                            <div className="form-inline flex-nowrap gx-3">
                                                <div className="form-wrap">
                                                    <input type="text" defaultValue={this.state.phrase} onChange={e => this.state.phrase = e.target.value} placeholder="شماره مالیاتی یا طرف حساب صورتحساب" className="form-control" />
                                                </div>
                                                <div className="form-wrap">
                                                    <select defaultValue={this.state.filters.type} onChange={e => this.state.filters.type = parseInt(e.target.value)} className="form-control">
                                                        <option value="0">همه وضعیتها</option>
                                                        <option value="1">ارسال نشده</option>
                                                        <option value="2">ارسال شده بدون استعلام</option>
                                                        <option value="3">استعلام شده دارای خطا</option>
                                                        <option value="4">ارسال شده موفق</option>
                                                    </select>
                                                </div>
                                                <div className="form-wrap">
                                                    <select defaultValue={this.state.filters.inp} onChange={e => this.state.filters.inp = parseInt(e.target.value)} className="form-control">
                                                        <option value="0">همه الگوهای</option>
                                                        <option value="1">فروش</option>
                                                        <option value="2">فروش ارزی</option>
                                                        <option value="3">طلا و جواهر و پلاتین</option>
                                                        <option value="4">پیمانکاری</option>
                                                        <option value="5">قبوض خدماتی</option>
                                                        <option value="6">بلیت هواپیما</option>
                                                        <option value="7">صادرات</option>
                                                    </select>
                                                </div>
                                                <div className="form-wrap">
                                                    <select defaultValue={this.state.filters.ins} onChange={e => this.state.filters.ins = parseInt(e.target.value)} className="form-control">
                                                        <option value="0">همه موضوعات</option>
                                                        <option value="1">اصلی</option>
                                                        <option value="2">ابطالی</option>
                                                        <option value="3">اصلاحی</option>
                                                        <option value="4">برگشت از فروش</option>
                                                    </select>
                                                </div>
                                                <div className="btn-wrap">
                                                    <span className="d-none d-md-block"><button onClick={e => this.refreshData(e)} type="submit" className="btn btn-dim btn-outline-light hand"><em class="icon ni ni-search"></em> جستجو</button></span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col"><span className="sub-text">ردیف</span></div>
                                            <div className="nk-tb-col"><span className="sub-text">الگو</span></div>
                                            <div className="nk-tb-col"><span className="sub-text">موضوع</span></div>
                                            <div className="nk-tb-col"><span className="sub-text">نوع</span></div>
                                            <div className="nk-tb-col tb-col-sm"><span className="sub-text text-center">سریال</span></div>
                                            <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">شماره منحصربفرد مالیاتی</span></div>
                                            <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">شماره مالیاتی مرجع</span></div>
                                            <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">تاریخ</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text text-center">طرف حساب</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text text-center">مبلغ کل</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text text-center">وضعیت</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text">عملیات</span></div>
                                        </div>
                                        {this.state.data.records.map((record, index) =>
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">{this.genRowNumber(index)}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">{record.inpString}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">{record.insString}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">{record.intyString}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-sm">
                                                    <span className="sub-text text-center">{record.inno}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.taxId}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.irtaxId}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-lg">
                                                    <span className="sub-text text-center">{record.indatimPersian}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-lg">
                                                    <span className="sub-text text-center">{record.customerName}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-lg">
                                                    <span className="sub-text text-center">{this.money(record.tbill)}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-lg text-center">
                                                    {record.status === 1 && <span class="tb-status text-info">{record.statusString}</span>}
                                                    {record.status === 2 && <span class="tb-status text-warning">{record.statusString}</span>}
                                                    {record.status === 3 && <span class="tb-status text-danger">{record.statusString}</span>}
                                                    {record.status === 4 && <span class="tb-status text-success">{record.statusString}</span>}
                                                </div>
                                                <div className="nk-tb-col nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li>
                                                            <div className="drodown">
                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <ul className="link-list-opt no-bdr">
                                                                        {this.getToolBars(record)}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.createPaging()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}