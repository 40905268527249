import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Link } from 'react-router-dom';
import { AuthFooter } from './AuthFooter';

export default class Remember extends Base {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            code: '',
            password: '',
            loading: true,
            id: 0
        };
        window.document.body.classList = "nk-body bg-white npc-general pg-auth";
    }
    reset(e) {
        e.preventDefault();

        if (this.isBlank(this.state.mobile)) {
            this.showErorrMessage('شماره موبایل خود را وارد نمایید');
            return;
        }
        this.httpPost("api/v1/remember/do", {
            mobile: this.state.mobile,
        })
        .then(data => {
            if (data.status === 1) {
                this.setState({ id: data.id });
            }
            else this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    approve(e) {
        e.preventDefault();

        if (this.isBlank(this.state.code)) {
            this.showErorrMessage('کد دریافتی از طریق پیامک را وارد نمایید');
            return;
        }
        if (this.isBlank(this.state.password)) {
            this.showErorrMessage('کلمه عبور جدیدی را وارد نمایید');
            return;
        }
        this.httpPost("api/v1/remember/approve", {
            code: this.state.code,
            password: this.state.password,
            id: this.state.id
        })
        .then(data => {
            if (data.status === 1) {
                this.showSuccessMessage('کلمه عبور شما با موفقیت تغییر یافت');
                this.setState({
                    redirect: "/login"
                });
            }
            else this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    componentDidMount() {
        this.httpPost("api/v1/login/get")
        .then(data=>{
            if (data.logged == 1) window.location.href = "/";
            else {
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                    <Link to="/" className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src="/assets/images/logo.webp" alt="" />
                        <img className="logo-dark logo-img logo-img-lg" src="/assets/images/logo-dark.webp" alt="" />
                    </Link>
                </div>
                <div className="card card-bordered" key={this.state.id}>
                    <div className="card-inner card-inner-lg">
                        <div className="nk-block-head">
                            <div className="nk-block-head-content">
                                <h4 className="nk-block-title">بازیابی کلمه عبور</h4>
                            </div>
                        </div>
                        <form method="post" onSubmit={e => this.reset(e)}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" for="default-01">شماره موبایل</label>
                                </div>
                                <div className="form-control-wrap">
                                    <input onChange={e => this.state.mobile = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="شماره موبایل" />
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-lg btn-primary btn-block">دریافت کد تایید</button>
                            </div>
                        </form>
                        <div className="form-note-s2 text-center pt-4"><Link to="/login/">صفحه ورود</Link></div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </div>
    }
    renderApproveForm() {
        return <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                    <Link to="/" className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src="/assets/images/logo.webp" alt="" />
                        <img className="logo-dark logo-img logo-img-lg" src="/assets/images/logo-dark.webp" alt="" />
                    </Link>
                </div>
                <div className="card card-bordered" key={this.state.id}>
                    <div className="card-inner card-inner-lg">
                        <div className="nk-block-head">
                            <div className="nk-block-head-content">
                                <h4 className="nk-block-title">تایید شماره موبایل</h4>
                            </div>
                        </div>
                        <form method="post" onSubmit={e => this.approve(e)}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" for="default-01">کد دریافتی</label>
                                </div>
                                <div className="form-control-wrap">
                                    <input onChange={e => this.state.code = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="کد دریافتی" />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" for="default-01">کلمه عبور جدید</label>
                                </div>
                                <div className="form-control-wrap">
                                    <input onChange={e => this.state.password = e.target.value} type="password" className="form-control form-control-lg" id="default-01" placeholder="کلمه عبور جدید" />
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-lg btn-primary btn-block">تایید</button>
                            </div>
                        </form>
                        <div className="form-note-s2 text-center pt-4"><Link to="/login/">صفحه ورود</Link></div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : this.state.id === 0 ? this.renderForm() : this.renderApproveForm();

        return (
            <div>
                {this.state.lockScreen === 1 && this.lockScreen()}
                {contents}
            </div>
        );
    }
}