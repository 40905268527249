
import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class MyPlans extends Base {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            id_to_remove: null,
        }; 
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        this.setState({ loading: true });

        this.httpPost("api/v1/common/getplans", {
            page: this.state.page,
            count: this.state.per_page,
            phrase: this.state.phrase,
            filters: this.state.filters
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {

                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                });
            }
        })
    }
    buy(planId) {
        this.httpPost("api/v1/onlinerecord/create", {
            planId: planId,
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                var form = document.createElement('form');
                form.setAttribute('method', 'POST');
                form.setAttribute('action', 'https://bpm.shaparak.ir/pgwchannel/startpay.mellat');
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('name', 'RefId');
                hiddenField.setAttribute('value', data.refId);
                form.appendChild(hiddenField);
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            }
            else {
                if (data.status === 2) {
                    this.showSuccessMessage("طرح انتخابی با موفقیت برای شما فعال گردید");
                }
                else
                this.showErorrMessage(data.message);
            }
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    renderForm() {
        return <div>
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">پلنها و طرح ها</h3>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    {this.state.data.records.length <= 0 ?
                        <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                        <div className="alert-text">
                                            <h6>توجه</h6>
                                            <p>در حال حاضر هیچ طرح فعالی وجود ندارد</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="row g-gs">
                            {this.state.data.records.map(record =>
                                <div className="col-md-6 col-xxl-3">
                                    <div className="card card-bordered pricing">
                                        <div className="pricing-head">
                                            <div className="pricing-title">
                                                <h4 className="card-title title">{record.title}</h4>
                                            </div>
                                            <div className="card-text">
                                                <div className="row">
                                                    {record.price > 0 ?
                                                        <div className="col-6">
                                                            <span className="h4 fw-500">{this.money(record.price)}</span>
                                                            <span className="sub-text">ریال</span>
                                                        </div>
                                                        :
                                                        <div className="col-6">
                                                            <span className="h4 fw-500">رایگان</span>
                                                        </div>
                                                    }
                                                    <div className="col-6">
                                                        <span className="h4 fw-500">{record.validDays}</span>
                                                        <span className="sub-text">روز</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="pricing-features">
                                                <li><span className="w-50">تعداد صورتحساب مجاز برای ثبت</span> - <span className="ms-auto">{this.money(record.headerCount)} صورتحساب</span></li>
                                                <li><span className="w-50">تعداد شرکت مجاز برای ثبت</span> - <span className="ms-auto">{this.money(record.companyCount)} شرکت</span></li>
                                            </ul>
                                            <div className="pricing-action">
                                                <button className="btn btn-outline-light" onClick={e => this.buy(record.id)}>فعال سازی این طرح</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}