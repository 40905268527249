import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Stuff extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/stuff/get", {
            id: this.getCurrentFormId(),
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    save(e) {    
        e.preventDefault();
        this.httpPost("api/v1/stuff/save", {
            id: this.getCurrentFormId(),
            companyId: this.state.data.global.company.id,
            name: this.state.data.record.name,
            unitId: this.state.data.record.unitId,
            taxNumber: this.state.data.record.taxNumber,
            price: parseInt(this.state.data.record.price),
            taxRate: parseInt(this.state.data.record.taxRate),
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                this.setState({
                    redirect: '/stuffs'
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return<div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
                                    <div className="nk-block-head nk-block-head-lg wide-sm">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub">
                                                <a className="back-to" to="/stuffs/"><em className="icon ni ni-arrow-left"></em><span>فهرست کالاها/خدمات</span></a>
                                             </div>
                                        </div>
                                    </div>
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="card-head">
                                                    <h5 className="card-title">اطلاعات کالا / خدمت</h5>
                                                </div>
                                                <form method="post" onSubmit={e=>this.save(e)} className="row g-4">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">عنوان</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.name} onChange={e => this.state.data.record.name = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">واحد اندازه گیری</label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-control" type="text" defaultValue={this.state.data.record.unitId} onChange={e => this.state.data.record.unitId = parseInt(e.target.value)}>
                                                                    <option value='0'>انتخاب نمایید...</option>
                                                                    {this.state.data.units.map(record =>
                                                                        <option value={record.id}>{record.name}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">کد مالیاتی</label>
                                                        <div className="form-control-wrap">
                                                            <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.taxNumber} onChange={e => this.state.data.record.taxNumber = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">نرخ مالیات</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="2" className="form-control" type="text" defaultValue={this.state.data.record.taxRate} onChange={e => this.state.data.record.taxRate = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">قیمت پیش فرض</label>
                                                            <div className="form-control-wrap">
                                                                <input maxLength="13" className="form-control" type="text" defaultValue={this.state.data.record.price} onChange={e => this.state.data.record.price = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}