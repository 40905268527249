import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class PaymentFailed extends Base {
    constructor(props) {
        super(props);
        this.state = {
            economicCode: '',
            data: null,
            resualt: null,
            loading: true
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/common/get")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return<div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered card-stretch">
                                            <div className="card-inner-group">
                                                <div className="example-alert">
                                                    <div className="alert alert-pro alert-warning">
                                                        <div className="alert-text">
                                                            <h6>خطا</h6>
                                                            <p>عملیات پرداخت انجام نپذیرفت، در صورت کسر شدن مبلغ ظرف 72 ساعت آینده به حساب شما عودت داده خواهد شد.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}