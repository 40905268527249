import React from 'react';
import Base from './Base';
import { Link } from 'react-router-dom';
import { AuthFooter } from './AuthFooter';

export default class Register extends Base {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            fname: '',
            lname: '',
            password: '',
            nationalId: '',
            id: 0,
            code: '',
            loading: true,
            passwordFieldType: 'password'
        };
        window.document.body.classList = "nk-body bg-white npc-general pg-auth";
    }
    register(e) {
        e.preventDefault();

        if (this.isBlank(this.state.fname)) {
            this.showErorrMessage('نام خود را وارد نمایید');
            return;
        }
        if (this.isBlank(this.state.lname)) {
            this.showErorrMessage('نام خانوادگی خود را وارد نمایید');
            return;
        }
        if (this.isBlank(this.state.nationalId)) {
            this.showErorrMessage('کد ملی خود را وارد نمایید');
            return;
        } 
        if (this.isBlank(this.state.mobile)) {
            this.showErorrMessage('شماره موبایل خود را وارد نمایید');
            return;
        }
        if (this.isBlank(this.state.password)) {
            this.showErorrMessage('کلمه عبور مد نظر خود را وارد نمایید');
            return;
        }
        this.httpPost("api/v1/register/do", {
            mobile: this.state.mobile,
            fname: this.state.fname,
            lname: this.state.lname,
            password: this.state.password,
            nationalId: this.state.nationalId
        })
        .then(data => {
            if (data.status === 1) {
                this.setState({ id: data.id });
            }
            else this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    approve(e) {
        e.preventDefault();

        if (this.isBlank(this.state.code)) {
            this.showErorrMessage('کد دریافتی از طریق پیامک را وارد نمایید');
            return;
        }
        this.httpPost("api/v1/register/approve", {
            code: this.state.code,
            id: this.state.id
        })
        .then(data => {
            if (data.status === 1) {
                window.location.href = "/";
            }
            else this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    componentDidMount() {
        this.httpPost("api/v1/login/get")
        .then(data=>{
            if (data.logged == 1) window.location.href = "/";
            else {
                this.setState({
                    loading: false
                });
            }
        });
    }
    changePasswordFieldType() {
        if (this.state.passwordFieldType == "password") this.setState({ passwordFieldType: "text" });
        else this.setState({ passwordFieldType: "password" });
    }
    renderForm() {
        return <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <Link to="/" className="logo-link">
                            <img className="logo-light logo-img logo-img-lg" src="/assets/images/logo.webp" alt="" />
                            <img className="logo-dark logo-img logo-img-lg" src="/assets/images/logo-dark.webp" alt="" />
                        </Link>
                    </div>
                    <div className="card card-bordered">
                        <div className="card-inner card-inner-lg">
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    <h4 className="nk-block-title">ایجاد حساب کاربری</h4>
                                </div>
                            </div>
                            <form method="post" onSubmit={e => this.register(e)}>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="default-01">نام</label>
                                    </div>
                                <div className="form-control-wrap">
                                    <input maxLength="100" defaultValue={this.state.fname} onChange={e => this.state.fname = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="نام" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="default-01">نام خانوادگی</label>
                                    </div>
                                <div className="form-control-wrap">
                                    <input maxLength="100" defaultValue={this.state.lname} onChange={e => this.state.lname = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="نام خانوادگی" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="default-01">کدملی</label>
                                    </div>
                                <div className="form-control-wrap">
                                    <input maxLength="10" defaultValue={this.state.nationalId} onChange={e => this.state.nationalId = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="کد ملی" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="default-01">شماره موبایل</label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <input maxLength="11" defaultValue={this.state.mobile} onChange={e => this.state.mobile = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="شماره موبایل" />
                                        </div>
                                    </div>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="default-01">کلمه عبور</label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <a onClick={e => this.changePasswordFieldType()} className="form-icon form-icon-right passcode-switch lg hand" data-target="password">
                                            <em className={"passcode-icon icon-" + (this.state.passwordFieldType == "password" ? "show" : "hide") + " icon ni ni-eye"}></em>
                                            <em className={"passcode-icon icon-" + (this.state.passwordFieldType == "password" ? "hide" : "show") + " icon ni ni-eye-off"}></em>
                                        </a>
                                        <input defaultValue={this.state.password} onChange={e => this.state.password = e.target.value} type={this.state.passwordFieldType}  className="form-control form-control-lg" id="password" placeholder="کلمه عبور" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-lg btn-primary btn-block">عضویت</button>
                                </div>
                            </form>
                            <div className="form-note-s2 text-center pt-4"> حساب کاربری دارید؟ <Link to="/login/">ورود</Link></div>
                        </div>
                    </div>
            </div>
            <AuthFooter />
                </div>
    }
    renderApproveForm() {
        return <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                    <Link to="/" className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src="/assets/images/logo.webp" alt="" />
                        <img className="logo-dark logo-img logo-img-lg" src="/assets/images/logo-dark.webp" alt="" />
                    </Link>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner card-inner-lg">
                        <div className="nk-block-head">
                            <div className="nk-block-head-content">
                                <h4 className="nk-block-title">تایید شماره موبایل</h4>
                            </div>
                        </div>
                        <form method="post" onSubmit={e => this.approve(e)}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" for="default-01">کد تایید</label>
                                </div>
                                <div className="form-control-wrap">
                                    <input key={this.state.code} defaultValue={this.state.code} onChange={e => this.state.code = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="کد تایید" />
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-lg btn-primary btn-block">تایید</button>
                            </div>
                        </form>
                        <div className="form-note-s2 text-center pt-4"> حساب کاربری دارید؟ <Link to="/login/">ورود</Link></div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </div>
    }
    render() {
        let contents = this.state.loading ? this.renderLoading() : this.state.id === 0 ? this.renderForm() : this.renderApproveForm();

        return (
            <div>
                {this.state.lockScreen === 1 && this.lockScreen()}
                {contents}
            </div>
        );
    }
}