import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';
import SelectAsync from 'react-select/async';
import { NavLink } from 'react-router-dom';
//https://s-yadav.github.io/react-number-format/docs/numeric_format/
import NumberFormat from 'react-number-format';

export default class InvoiceView extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'invoice',
            id: props.Id,
            isNew: props.IsNew,
            ins: props.Ins,
            inp: props.Inp,
            data: null,
            loading: true,
            bodyVersion: '',
            paymentVeriosn: '',
            t_am: 0,
            t_nw: 0,
            t_fee: 0,
            t_prdis: 0,
            t_dis: 0,
            t_adis: 0,
            t_vam: 0,
            t_tsstam: 0,
            t_pv: 0
        };
    }
    componentDidMount() {
        this.httpPost("api/v1/invoice/get", {
            id: this.getCurrentFormId(),
            ins: this.state.ins,
            inp: this.state.inp,
            isNew: this.state.isNew
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                window.companyId = data.global.company.id;

                for (var i = 0; i < data.bodies.length; i++) { 
                    this.state.t_am += data.bodies[i]["am"];
                    this.state.t_nw += data.bodies[i]["nw"];
                    this.state.t_fee += data.bodies[i]["fee"];
                    this.state.t_prdis += data.bodies[i]["prdis"];
                    this.state.t_dis += data.bodies[i]["dis"];
                    this.state.t_adis += data.bodies[i]["adis"];
                    this.state.t_vam += data.bodies[i]["vam"];
                    this.state.t_tsstam += data.bodies[i]["tsstam"];
                }

                for (var i = 0; i < data.payments.length; i++) {
                    this.state.t_pv += data.payments[i]["pv"];
                }

                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    getBodyColumnCount() {
        if (this.state.data.record.inp === 1) return 11;
        if (this.state.data.record.inp === 2) return 14;
        if (this.state.data.record.inp === 3) return 15;
        if (this.state.data.record.inp === 4) return 10;
        if (this.state.data.record.inp === 5) return 13;
        if (this.state.data.record.inp === 6) return 11;
        if (this.state.data.record.inp === 7) return 13;
        return 0;
    }
    renderForm() {
        return (
            <form onSubmit={e => this.save(e)} className="row g-4">
                <div className="nk-block-head">
                    <div className="nk-block-between g-3">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">الگوی صورتحساب: <strong className="text-primary small">{this.state.data.record.inpString}</strong></h3>
                        </div>
                        <div className="nk-block-head-content">
                            <NavLink to="/invoices/" className="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em className="icon ni ni-arrow-left"></em><span>برگشت</span></NavLink>
                            <NavLink to="/invoices" className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em className="icon ni ni-arrow-left"></em></NavLink>
                            {this.state.data.record.id > 0 && <a onClick={e => this.getPdf(e)} className="btn btn-white btn-outline-light"><em class="icon ni ni-file-pdf"></em><span>خروجی PDF</span></a>}
                        </div>
                    </div>
                </div>
                {this.state.data.record.status === 2 && 
                    <div className="example-alert">
                        <div className="alert alert-pro alert-warning">
                            <div className="alert-text">
                                <h6>توجه</h6>
                                <p>این صورتحساب در حال حاضر در لیست انتظار سامانه مودیان قرار دارد و هنوز تایید/رد نشده است</p>
                            </div>
                        </div>
                    </div>
                }
                {this.state.data.record.status === 3 &&
                    <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                                <h6>نتایج استعلام صورتحساب از سامانه مودیان</h6>
                                <p>این صورتحساب به علت وجود خطاهای زیر توسط سامان مورد پذیرش قرار نگرفت. لطفا خطاهای زیر را بررسی و اصلاح نموده و مجدد صورتحساب را ارسال نمایید</p>                        <ul>
                                    {this.state.data.erros.map(record =>
                                        <li>
                                            {record.msg}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {this.state.data.record.status === 4 &&
                    <div className="example-alert">
                        <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                                <h6>تبریک</h6>
                                <p>این صورتحساب با موفقیت در کارپوشه سامانه مودیان ثبت شده است</p>
                            </div>
                        </div>
                    </div>
                }

                <div className="card card-bordered card-preview">
                    <div className="card-inner">
                        <div className="tab-content">
                            <div className="tab-pane active" id="header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="form-label">طرف حساب:</label>
                                            <div className="form-control-wrap">
                                                {this.state.data.record.customerName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">نوع صورتحساب</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.inty === 1 ? "نوع اول (فروش به مودیان مالیاتی)" : ""}
                                                    {this.state.data.record.inty === 2 ? "نوع دوم (فروش به مصرف کننده نهایی)" : ""}
                                                    {this.state.data.record.inty === 3 ? "نوع سوم (فروش با کارتخوان یا پایانه فروشگاهی)" : ""}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">تاریخ صورتحساب</label>
                                            <div className="form-control-wrap">
                                                {this.state.data.record.indatimPersian}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">سریال</label>
                                            <div className="form-control-wrap">
                                                {this.state.data.record.inno}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">شماره فاکتور (اختیاری)</label>
                                            <div className="form-control-wrap">
                                                {this.state.data.record.factorNo}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {this.state.data.record.inp === 2 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">شماره پروانه گمرکی</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.scln}
                                        </div>
                                                </div>
                                            </div>}
                                    {this.state.data.record.inp === 2 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">کد گمرک محل اظهار</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.scc}
                                        </div>
                                                </div>
                                            </div>}

                                    {this.state.data.record.inp === 4 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">شناسه یکتای ثبت قرارداد فروشنده</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.crn}
                                        </div>
                                                </div>
                                            </div>}

                                        {this.state.data.record.inp === 5 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">شماره اشتراک / شناسه قبص بهره بردار</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.billid}
                                        </div>
                                                </div>
                                            </div>}

                                    {this.state.data.record.inp === 6 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">نوع پرواز</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.ft === 1 ? "داخلی" : ""}
                                                    {this.state.data.record.ft === 2 ? "خارجی" : ""}
                                        </div>
                                    </div>
                                            </div>}

                                        {this.state.data.record.inp === 7 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">شماره کوتاژ اظهارنامه گمرکی</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.cdcn}
                                        </div>
                                    </div>
                                            </div>}
                                            {this.state.data.record.inp === 7 && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">تاریخ کوتاژ اظهارنامه گمرکی</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.cdcdPersian}
                                        </div></div>
                                            </div>}
                                            {this.state.data.record.sendToTax &&
                                                <div className="col-md-3"><div className="form-group">
                                                    <label className="form-label">شماره منحصربفرد</label>
                                                    <div className="form-control-wrap">
                                                        {this.state.data.record.taxId}
                                                    </div>
                                            </div>
                                                </div>
                                            }
                                            {this.state.data.record.ins !== 1  && <div className="col-md-3"><div className="form-group">
                                                <label className="form-label">شماره منحصربفرد مرجع</label>
                                                <div className="form-control-wrap">
                                                    {this.state.data.record.irtaxId}
                                            </div>
                                        </div>
                                            </div>}
                                    </div>
                            </div>
                                <h4>اقلام صورتحساب</h4>
                                <table className="table table-ulogs invoceEntryTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center"><span className="overline-title">کالا / خدمات</span></th>
                                            <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">واحد</span></th>
                                            <th className="text-center" style={{ "width": "60px" }}><span className="overline-title">تعداد/مقدار</span></th>
                                            {this.state.data.record.inp === 7 && <th className="text-center" style={{ "width": "55px" }}><span className="overline-title">وزن خالص</span></th>}
                                            <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ واحد</span></th>

                                            {this.state.data.record.inp === 2 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">میزان ارز</span></th>}

                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">نوع ارز</span></th>}
                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">نرخ برابری ارز و ریال</span></th>}

                                            {this.state.data.record.inp === 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">ارزش ریالی</span></th>}
                                            {this.state.data.record.inp === 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">ارزش ارزی</span></th>}

                                            {this.state.data.record.inp !== 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ قبل تخفیف</span></th>}
                                            {this.state.data.record.inp !== 7 && <th className="text-center" style={{ "width": "70px" }}><span className="overline-title">تخفیف</span></th>}
                                            {this.state.data.record.inp !== 7 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ بعد تخفیف</span></th>}

                                            <th className="text-center" style={{ "width": "55px" }}><span className="overline-title">نرخ مالیات</span></th>
                                            <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مالیات</span></th>

                                            {this.state.data.record.inp === 5 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">موضوع سایر مالیات</span></th>}
                                            {this.state.data.record.inp === 5 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">نرخ سایر مالیات</span></th>}
                                            {this.state.data.record.inp === 5 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">مبلغ سایر مالیات</span></th>}

                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">اجرت ساخت</span></th>}
                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">سود فروشنده</span></th>}
                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">حق العمل</span></th>}
                                            {this.state.data.record.inp === 3 && <th className="text-center" style={{ "width": "80px" }}><span className="overline-title">جمع کل اجرت، حق العمل و سود</span></th>}

                                            <th className="text-center" style={{ "width": "90px" }}><span className="overline-title">جمع کل</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.bodies.map(record =>
                                            <tr key={record.id}>
                                                <td className="text-center">
                                                    {record.stuffName + " " + record.stuffTaxNumber }
                                                </td>
                                                <td className="text-center">
                                                    {record.unitName }
                                                </td>
                                                <td className="text-center">
                                                    {record.am}
                                                </td>
                                                {this.state.data.record.inp === 7 && <td className="text-center">
                                                    {record.nw}
                                                </td>}
                                                <td className="text-center">
                                                    {record.fee}
                                                </td>
                                                
                                                {this.state.data.record.inp === 2 && <td className="text-center">{record.cfee}</td>}
                                                {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td className="text-center">
                                                    {record.cut}
                                                </td>}
                                                {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td className="text-center">{record.exr}</td>}
                                                {this.state.data.record.inp === 7 && <td className="text-center">{this.money(record.ssrv)}</td>}
                                                {this.state.data.record.inp === 7 && <td className="text-center">{this.money(record.sscv)}</td>}

                                                {this.state.data.record.inp !== 7 && <td className="text-center">{this.money(record.prdis)}</td>}
                                                {this.state.data.record.inp !== 7 && <td className="text-center">
                                                    {this.money(record.dis)}
                                                </td>}
                                                {this.state.data.record.inp !== 7 && <td className="text-center">{this.money(record.adis)}</td>}

                                                <td className="text-center">{record.vra}</td>
                                                <td className="text-center">{this.money(record.vam)}</td>

                                                {this.state.data.record.inp === 5 && <td className="text-center">{record.odt}</td>}
                                                {this.state.data.record.inp === 5 && <td className="text-center">{record.odr}</td>}
                                                {this.state.data.record.inp === 5 && <td className="text-center">{this.money(record.odam)}</td>}

                                                {this.state.data.record.inp === 3 && <td className="text-center">{this.money(record.consfee)}</td>}
                                                {this.state.data.record.inp === 3 && <td className="text-center">{record.spro}</td>}
                                                {this.state.data.record.inp === 3 && <td className="text-center">{record.bros}</td>}
                                                {this.state.data.record.inp === 3 && <td className="text-center">{this.money(record.tcpbs)}</td>}
                                                
                                                <td className="text-center">{this.money(record.tsstam)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ "background-color": "#e9e8e1" }}>
                                            <td colSpan="2" className="text-center">
                                                <b>مجموع آیتم ها</b>
                                            </td>
                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_am)}</labe>
                                            </td>
                                            {this.state.data.record.inp === 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_nw)}</labe>
                                            </td>}
                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_fee)}</labe>
                                            </td>

                                            {this.state.data.record.inp === 2 && <td></td>}
                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td></td>}
                                            {(this.state.data.record.inp === 7 || this.state.data.record.inp === 2) && <td></td>}
                                            {this.state.data.record.inp === 7 && <td></td>}
                                            {this.state.data.record.inp === 7 && <td></td>}

                                            {this.state.data.record.inp !== 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_prdis)}</labe>
                                            </td>}
                                            {this.state.data.record.inp !== 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_dis)}</labe>
                                            </td>}
                                            {this.state.data.record.inp !== 7 && <td className="text-center">
                                                <labe>{this.money(this.state.t_adis)}</labe>
                                            </td>}
                                            <td className="text-center"></td>
                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_vam)}</labe>
                                            </td>

                                            {this.state.data.record.inp === 5 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 5 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 5 && <td className="text-center"></td>}

                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}
                                            {this.state.data.record.inp === 3 && <td className="text-center"></td>}

                                            <td className="text-center">
                                                <labe>{this.money(this.state.t_tsstam)}</labe>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>

                            <h4>پرداختهای صورتحساب</h4>
                            <table className="table table-ulogs invoceEntryTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center"><span className="overline-title">نوع پرداخت</span></th>
                                            <th style={{ "width": "120px" }} className="text-center"><span className="overline-title">کدملی پرداخت کنننده</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">شماره کارت</span></th>
                                            <th style={{ "width": "140px" }} className="text-center"><span className="overline-title">تاریخ و زمان</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">مبلغ</span></th>
                                            <th style={{ "width": "120px" }} className="text-center"><span className="overline-title">شماره سوییچ پرداخت</span></th>
                                            <th style={{ "width": "120px" }} className="text-center"><span className="overline-title">شماره پذیرنده فروشگاهی</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">شماره پایانه</span></th>
                                            <th style={{ "width": "80px" }} className="text-center"><span className="overline-title">شماره پیگیری</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.payments.map(record =>
                                            <tr key={record.id}>
                                                <td>
                                                    {record.pmt === 1 ? "چک" : ""}
                                                    {record.pmt === 2 ? "تهاتر" : ""}
                                                    {record.pmt === 3 ? "وجه نقد" : ""}
                                                    {record.pmt === 4 ? "pos" : ""}
                                                    {record.pmt === 5 ? "درگاه اینترنتی" : ""}
                                                    {record.pmt === 6 ? "کارت به کارت" : ""}
                                                    {record.pmt === 7 ? "انتقال به حساب" : ""}
                                                    {record.pmt === 8 ? "سایر موارد" : ""}
                                                </td>
                                                <td>{record.pid}</td>
                                                <td>{record.pcn}</td>
                                                <td>{record.pdtPersian}</td>
                                                <td>{this.money(record.pv)}</td>
                                                <td>{record.iinn}</td>
                                                <td>{record.pid}</td>
                                                <td>{record.acn}</td>
                                                <td>{record.trmn}</td>
                                                <td>{record.trn}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ "background-color": "#e9e8e1" }}>
                                            <td colSpan="4" className="text-center">
                                                <b>مجموع آیتم ها</b>
                                            </td>
                                            <td className="text-center" key={this.state.t_pv}>{this.money(this.state.t_pv)}</td>
                                            <td colSpan="4" className="text-center"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

            </form>
        );
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}