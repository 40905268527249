import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Company extends Base {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            password: '',
            data: null,
            loading: true
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/profile/get")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    save(e) {
        e.preventDefault();
        this.httpPost("api/v1/profile/save", {
            fname: this.state.data.record.fname,
            lname: this.state.data.record.lname,
            password: this.state.password
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت بروز رسانی شد");
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return <div className="nk-content ">
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="components-preview wide-md mx-auto">
                            <div className="nk-block nk-block-lg">
                                <div className="card card-bordered">
                                    <div className="card-inner">
                                        <div className="card-head">
                                            <h5 className="card-title">اطلاعات حساب کاربری</h5>
                                        </div>
                                        <form method="post" onSubmit={e => this.save(e)} className="row g-4">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-label">نام</label>
                                                    <div className="form-control-wrap">
                                                        <input className="form-control" type="text" defaultValue={this.state.data.record.fname} onChange={e => this.state.data.record.fname = e.target.value} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-label">نام خانوادگی</label>
                                                    <div className="form-control-wrap">
                                                        <input className="form-control" type="text" defaultValue={this.state.data.record.lname} onChange={e => this.state.data.record.lname = e.target.value} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-label">کلمه عبور</label>
                                                    <div className="form-control-wrap">
                                                        <input className="form-control" type="password" onChange={e => this.state.password = e.target.value} />
                                                        <p>در صورت عدم نیاز به تغییر کلمه عبور لطفا مقدار این فیلد را خالی قرار دهید</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}