import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export class Home extends Base {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/dashboard")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    renderForm() {
        return <div className="nk-content-body">
                    <div class="nk-block-head nk-block-head-sm">
                        <div class="nk-block-between">
                            <div class="nk-block-head-content">
                                <h3 class="nk-block-title page-title">داشبرد</h3>
                                <div class="nk-block-des text-soft">
                                    <p>{this.state.data.global.user.fname} {this.state.data.global.user.lname} عزیز خوش آمدید</p>
                                </div>
                            </div>
                        </div>
                    </div>

                     <ul className="row g-gs preview-icon-svg">
                            <li className="col-lg-3 col-6 col-sm-4">
                                <NavLink to="/invoices/" className="preview-icon-box card card-bordered">
                                    <div className="preview-icon-wrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                                            <rect x="12" y="5" width="55" height="68" rx="6" ry="6" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <path d="M78,15.8l-5.72,6.33L66.7,16l-5.33,5.91-5.82-6.46L49.67,22,44.13,15.8l-5.49,6.09L33,15.62,27.27,22l-6.16-6.83L21,15V79c0,3.33,2.43,6,5.43,6H72.57c3,0,5.43-2.69,5.43-6V15.76S77.94,15.79,78,15.8Z" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <circle cx="49.5" cy="35.5" r="2.5" fill="#c4cefe" stroke="#c4cefe" stroke-miterlimit="10" stroke-width="2" />
                                            <line x1="71" y1="50" x2="28" y2="49.86" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="71" y1="56" x2="28" y2="55.86" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="71" y1="61" x2="28" y2="60.86" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="28" y1="45" x2="38" y2="45" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="61" y1="45" x2="71" y2="45" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="28" y1="76" x2="41" y2="76" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="61" y1="76" x2="69" y2="76" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="59" y1="66" x2="69" y2="66" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <span className="preview-icon-name">{ this.state.data.invoice_count } صورتحساب ثبت شده</span>
                                </NavLink>
                                </li>
                                <li className="col-lg-3 col-6 col-sm-4">
                                    <NavLink to="/persons/" className="preview-icon-box card card-bordered">
                                        <div className="preview-icon-wrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                                                <rect x="5" y="7" width="60" height="56" rx="7" ry="7" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <rect x="25" y="27" width="60" height="56" rx="7" ry="7" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <rect x="15" y="17" width="60" height="56" rx="7" ry="7" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="15" y1="29" x2="75" y2="29" fill="none" stroke="#6576ff" stroke-miterlimit="10" stroke-width="2" />
                                                <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                                                <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                                                <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                                                <rect x="22" y="39" width="20" height="20" rx="2" ry="2" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <circle cx="32" cy="45.81" r="2" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path d="M29,54.31a3,3,0,0,1,6,0" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="49" y1="40" x2="69" y2="40" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="49" y1="51" x2="69" y2="51" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="49" y1="57" x2="59" y2="57" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="64" y1="57" x2="66" y2="57" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="49" y1="46" x2="59" y2="46" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="64" y1="46" x2="66" y2="46" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </svg>
                                        </div>
                                        <span className="preview-icon-name">{this.state.data.customer_count} مشتری ثبت شده</span>
                                    </NavLink>
                                </li>
                                <li className="col-lg-3 col-6 col-sm-4">
                                    <NavLink to="/stuffs/" className="preview-icon-box card card-bordered">
                                        <div className="preview-icon-wrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                                                <rect x="44" y="24" width="41" height="43" transform="translate(129 91) rotate(-180)" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path d="M71,37V33.6A1.6,1.6,0,0,0,69.4,32H54.6A1.6,1.6,0,0,0,53,33.6V48.39A1.61,1.61,0,0,0,54.61,50H69.4A1.6,1.6,0,0,0,71,48.4V37Z" fill="#eff1ff" />
                                                <line x1="60" y1="42" x2="60" y2="45" fill="none" stroke="#6576ff" stroke-width="1.5" />
                                                <line x1="58" y1="46" x2="56" y2="46" fill="none" stroke="#6576ff" stroke-width="1.5" />
                                                <rect x="64" y="35" width="4" height="4" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <rect x="56" y="35" width="4" height="4" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <rect x="64" y="42" width="4" height="4" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <polyline points="58 42 56 42 56 44" fill="none" stroke="#6576ff" stroke-width="1.5" />
                                                <path d="M48,53H78a0,0,0,0,1,0,0v9a0,0,0,0,1,0,0H48a2,2,0,0,1-2-2V55a2,2,0,0,1,2-2Z" transform="translate(124 115) rotate(-180)" fill="#e3e7fe" />
                                                <circle cx="70" cy="28" r="0.5" fill="none" stroke="#33d895" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <circle cx="75" cy="28" r="0.5" fill="none" stroke="#f4bd0e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <circle cx="80" cy="28" r="0.5" fill="none" stroke="#eb6459" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <rect x="5" y="9" width="40" height="72" rx="3" ry="3" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <rect x="9" y="22" width="32" height="43" fill="#fff" />
                                                <polyline points="31 34 35 34 35 38" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <polyline points="19 54 15 54 15 50" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <polyline points="15 38 15 34 19 34" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <polyline points="35 50 35 54 31 54" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="25" y1="41" x2="25" y2="47" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="22" y1="44" x2="28" y2="44" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <circle cx="25" cy="74" r="3" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line x1="23" y1="15" x2="28" y2="15" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </svg>
                                        </div>
                                        <span className="preview-icon-name">{this.state.data.stuff_count} کالا/خدمت ثبت شده</span>
                                    </NavLink>
                                </li>
                                <li className="col-lg-3 col-6 col-sm-4">
                                    <NavLink to="/invoice/upload/" className="preview-icon-box card card-bordered">
                                        <div className="preview-icon-wrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                                    <path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{ "fill":"#36c684"}} />
                                    <path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{"fill":"#95e5bd"}} />
                                    <path d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z" style={{ "fill":"#fff"}} />
                                            </svg>
                                        </div>
                                        <span className="preview-icon-name">افزودن اکسل صورتحساب</span>
                                    </NavLink>
                                </li>
                            </ul>
                 </div>

    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>,
                        <Footer />
                    ];

        return contents;
    }
}