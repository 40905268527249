import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from '../Base';
import { Header } from '../Header';
import { Footer } from '../Footer';

export default class Users extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'user',
            data: null,
            loading: true,
            id_to_remove: null,
            page: 1,
            per_page: 10,
            phrase: ''
        }; 
    }
    componentDidMount() {
        this.refreshData();
    }
    userLogin(id) {
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/login", {
            id: id
        })
        .then(data => {
            if (data.status === 1) {
                window.location.href = "/";
            }
            else {
                this.showErorrMessage(data.message);
            }
        })
    }
    renderForm() {
        return <div>
            <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                        <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">فهرست کاربران</h3>
                            <div className="nk-block-des text-soft">
                                <p>{this.money(this.state.data.recordsCount)} کاربر ثبت شده است</p>
                            </div>
                        </div>
                        <div className="nk-block-head-content">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                <div className="toggle-expand-content" data-content="pageMenu">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <NavLink to="/user/new/" className="btn btn-primary"><em className="icon ni ni-download-cloud"></em><span>افزودن کاربر جدید</span></NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                        {this.state.data.recordsCount <= 0 ?
                            <div className="card-inner-group">
                                <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                        <div className="alert-text">
                                            <h6>توجه</h6>
                                            <p>در حال حاضر هیچ کاربری ثبت نشده است</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="card-inner-group">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                        <form className="card-tools" mthod="post" onSubmit={e => this.refreshData(e)}>
                                            <div className="form-inline flex-nowrap gx-3">
                                                <div className="form-wrap">
                                                    <input type="text" defaultValue={this.state.phrase} onChange={e => this.state.phrase = e.target.value} placeholder="نام یا شماره موبایل یا کدملی" className="form-control" />
                                                </div>
                                                <div className="btn-wrap">
                                                    <span className="d-none d-md-block"><button onClick={e => this.refreshData(e)} type="submit" className="btn btn-dim btn-outline-light hand"><em class="icon ni ni-search"></em> جستجو</button></span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col"><span className="sub-text">ردیف</span></div>
                                            <div className="nk-tb-col"><span className="sub-text">نام و نام خانوادگی</span></div>
                                            <div className="nk-tb-col tb-col-sm"><span className="sub-text text-center">نقش</span></div>
                                            <div className="nk-tb-col tb-col-sm"><span className="sub-text text-center">موبایل</span></div>
                                            <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">کدملی</span></div>
                                            <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">وضعیت</span></div>
                                            <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">پلن</span></div>
                                            <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">ثبت نام</span></div>
                                            <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">صورتحساب</span></div>
                                            <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">مشتری</span></div>
                                            <div className="nk-tb-col text-end"><span className="sub-text">عملیات</span></div>
                                        </div>
                                        {this.state.data.records.map((record,index) =>
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">{this.genRowNumber(index)}</span>
                                                </div>
                                                <div className="nk-tb-col">
                                                    <span className="sub-text">{record.fName} {record.lName}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-sm">
                                                    {record.role === 1 && <span className="sub-text text-danger">ارشد</span>}
                                                    {record.role === 2 && <span className="sub-text text-success">کاربر</span>}
                                                    {record.role === 3 && <span className="sub-text text-warning">ادمین</span>}
                                                </div>
                                                <div className="nk-tb-col tb-col-sm">
                                                    <span className="sub-text text-center">{record.mobile}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.nationalId}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.statusString}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.planName}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.createDatePersian}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.invoiceCount}</span>
                                                </div>
                                                <div className="nk-tb-col tb-col-md">
                                                    <span className="sub-text text-center">{record.companyCountCount}</span>
                                                </div>
                                                
                                                <div className="nk-tb-col nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li className="nk-tb-action-hidden">
                                                            <NavLink to={"/user/edit/" + record.id + "/"} className="btn btn-trigger btn-icon" title="مشاهده و ویرایش">
                                                                <em className="icon ni ni-eye-fill"></em>
                                                            </NavLink>
                                                        </li>
                                                        <li className="nk-tb-action-hidden">
                                                            <a className="btn btn-trigger btn-icon hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove" title="حذف رکورد">
                                                                <em className="icon ni ni-cross-fill-c"></em>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <div className="drodown">
                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <ul className="link-list-opt no-bdr">
                                                                        <li><a className="hand" onClick={e => this.userLogin(record.id)} ><em className="icon ni ni-user"></em><span>ورود به پروفایل</span></a></li>
                                                                        <li><NavLink to={"/user/edit/" + record.id + "/"}><em className="icon ni ni-eye"></em><span>مشاهده و ویرایش</span></NavLink></li>
                                                                        <li><a className="hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove"><em className="icon ni ni-na"></em><span>حذف رکورد</span></a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                { this.createPaging() }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}