import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';
import { NavLink } from 'react-router-dom';

export default class Person extends Base {
    constructor(props) {
        super(props);
        this.state = {
            id: props.Id,
            data: null,
            loading: true
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/person/get", {
            id: this.getCurrentFormId(),
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    save(e) {
        e.preventDefault();
        this.httpPost("api/v1/person/save", {
            id: this.getCurrentFormId(), 
            companyId: this.state.data.global.company.id,
            parentId: this.isNull(this.state.data.record.parentId) ? 0 : this.state.data.record.parentId,
            address: this.state.data.record.address,
            pasportNo: this.state.data.record.pasportNo,
            economicCode: this.state.data.record.economicCode,
            nationalId: this.state.data.record.nationalId,
            email: this.state.data.record.email,
            mobile: this.state.data.record.mobile,
            name: this.state.data.record.name,
            phone: this.state.data.record.phone,
            type: this.state.data.record.type,
            zipCode: this.state.data.record.zipCode
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                this.setState({
                    redirect: '/persons/'
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }
    setPersonType(v,e){
        this.state.data.record.type = v;
        this.setState({data: this.state.data});
    }
    renderForm() {
        return<div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="components-preview wide-md mx-auto">
                                    <div className="nk-block-head nk-block-head-lg wide-sm">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub">
                                        <NavLink className="back-to" to="/persons/"><em className="icon ni ni-arrow-left"></em><span>فهرست مشتریان/اشخاص</span></NavLink>
                                             </div>
                                        </div>
                                    </div>
									<div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="card-head">
                                                    <h5 className="card-title">اطلاعات شخص / مشتری</h5>
                                                </div>
                                                <form method="post" onSubmit={e=>this.save(e)} className="row g-4">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">نوع شخص</label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-control" type="text" defaultValue={this.state.data.record.type} onChange={e => this.setPersonType(parseInt(e.target.value))}>
                                                                    <option value="1">حقیقی</option>
                                                                    <option value="2">حقوقی</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    {this.state.data.record.type === 2 && <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">شخص والد</label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-control" type="text" defaultValue={this.state.data.record.parentId} onChange={e => this.state.data.record.parentId = e.target.value}>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">{this.state.data.record.type === 1 ? "نام و نام خانوادگی" : "عنوان"}</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.name} onChange={e => this.state.data.record.name = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">{this.state.data.record.type === 1 ? "کدملی" : "شناسه ملی"}</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.nationalId} onChange={e => this.state.data.record.nationalId = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" style={{ display: "none" }}>
                                                        <div className="form-group">
                                                            <label className="form-label">کداقتصادی</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.economicCode} onChange={e => this.state.data.record.economicCode = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">شماره موبایل</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.mobile} onChange={e => this.state.data.record.mobile = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">شماره تلفن</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.phone} onChange={e => this.state.data.record.phone = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">آدرس ایمیل</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.email} onChange={e => this.state.data.record.email = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">کدپستی</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.zipCode} onChange={e => this.state.data.record.zipCode = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.data.record.type === 1 && <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">شماره گذرنامه</label>
                                                            <div className="form-control-wrap">
                                                                <input className="form-control" type="text" defaultValue={this.state.data.record.pasportNo} onChange={e => this.state.data.record.pasportNo = e.target.value} />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">آدرس</label>
                                                            <div className="form-control-wrap">
                                                                <textarea className="form-control" type="text" defaultValue={this.state.data.record.address} onChange={e => this.state.data.record.address = e.target.value} ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-lg btn-primary">ذخیره</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>,
                        <Footer />
                    ];

        return contents;
    }
}