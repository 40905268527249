import React from 'react';
import { Navigate } from 'react-router';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Upload extends Base {
    constructor(props) {
        super(props);
        this.state = {
            type: props.Type,
            file: '',
            loading: true
        }
    }
    componentDidMount() {
        this.httpPost("api/v1/common/get")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        });
    }

    uploadFile(event) {
        var _this = this;
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                _this.state.file = reader.result;
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }
    save(e) {
        e.preventDefault();
        if (this.isBlank(this.state.file)) {
            this.showErorrMessage('لطفا فایل اکسل مورد نظر خود را اپلود نمایید');
            return;
        }
        var url = "";
        var redirectUrl = "";
        if (this.state.type === 1) {
            url = "api/v1/invoice/upload";
            redirectUrl = "/invoices";
        }
        if (this.state.type === 2) {
            url = "api/v1/person/upload";
            redirectUrl = "/persons";
        }
        if (this.state.type === 3) {
            url = "api/v1/stuff/upload";
            redirectUrl = "/stuffs";
        }
        this.httpPost(url, {
            companyId: this.state.data.global.company.id,
            file: this.state.file
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت ذخیره شد");
                this.setState({
                    redirect: redirectUrl
                })
            }
            else {
                this.showErorrMessage(data.message);
                this.setState({
                    loading: false
                });
            }
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.")
        });
    }
    getExcelTemplate() {
        var p = "";
        if (this.state.type === 1) {
            p = "/template/invoice.xlsx";
        }
        if (this.state.type === 2) {
            p = "/template/person.xlsx";
        }
        if (this.state.type === 3) {
            p = "/template/stuff.xlsx";
        }
        return p;
    }
    renderForm() {
        return <div className="nk-content ">
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="components-preview wide-md mx-auto">
                            <div className="nk-block nk-block-lg">
                                <div className="card card-bordered">
                                    <div className="card-inner">
                                        <div className="card-head">
                                            <h5 className="card-title">ورود اکسل اطلاعات</h5>
                                        </div>
                                        <form method="post" onSubmit={e => this.save(e)} className="row g-4">
                                            <div className="uploadBox">
                                                <p>از طریق این بخش می تواند اطلاعات خود را به صورت دسته جمعی از طریق فایل اکسل وارد سامانه نمایید.</p>
                                                <p>برای اینکار بایستی ابتدا فایل اکسل نمونه را دانلود کرده و پس از تکمیل فایل از طریق فرم زیر ارسال نمایید.</p>
                                                <a style={{ "color": "#28bc36", "font-weight": "bold" }} href={this.getExcelTemplate()} target="_blank">دانلود فایل اکسل نمونه</a>
                                                <div style={{ "margin": "40px 0" }} >
                                                    <input type="file" onChange={e => this.uploadFile(e)} />
                                                    <button onClick={e => this.save(e)} className="btn btn-primary">ارسال</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() :
            [
                this.state.lockScreen === 1 && this.lockScreen(),
                this.getGlobalContainer(),
                <Header data={this.state.data} />,
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                        </div>
                    </div>
                </div>,
                <Footer />
            ];

        return contents;
    }
}