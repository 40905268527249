import React from 'react';
import Base from './Base';
import { Link } from 'react-router-dom';
import { AuthFooter } from './AuthFooter';

export default class Login extends Base {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            password: '',
            loading: true,
            passwordFieldType: 'password'
        };
        window.document.body.classList = "nk-body bg-white npc-general pg-auth";
    }
    login(e) {
        e.preventDefault();

        if (this.isBlank(this.state.mobile)) {
            this.showErorrMessage('شماره موبایل را وارد نمایید');
            return;
        }
        if (this.isBlank(this.state.password)) {
            this.showErorrMessage('کلمه عبور را وارد نمایید');
            return;
        }
        this.httpPost("api/v1/login/do", {
            mobile: this.state.mobile,
            password: this.state.password
        })
        .then(data => {
            if (data.status === 1) {
                window.location.href = "/";
            }
            this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    componentDidMount() {
        this.httpPost("api/v1/login/get")
        .then(data=>{
            if (data.logged == 1) window.location.href = "/";
            else {
                this.setState({
                    loading: false
                });
            }
        });
    }
    changePasswordFieldType() {
        if (this.state.passwordFieldType == "password") this.setState({ passwordFieldType: "text" });
        else this.setState({ passwordFieldType: "password" });
    }
    renderForm() {
        return <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <Link to="/" className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src="/assets/images/logo.webp" alt="" />
                        <img className="logo-dark logo-img logo-img-lg" src="/assets/images/logo-dark.webp" alt="" />
                        </Link>
                    </div>
                    <div className="card card-bordered">
                        <div className="card-inner card-inner-lg">
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    <h4 className="nk-block-title">ورود به سیستم</h4>
                                </div>
                            </div>
                            <form method="post" onSubmit={e => this.login(e)}>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="default-01">شماره موبایل</label>
                                    </div>
                                <div className="form-control-wrap">
                                    <input tabIndex="1" defaultValue={this.state.mobile} onChange={e => this.state.mobile = e.target.value} type="text" className="form-control form-control-lg" id="default-01" placeholder="شماره موبایل" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label" for="password">کلمه عبور</label>
                                        <Link className="link link-primary link-sm" to="/remember/">بازیابی کلمه عبور</Link>
                                    </div>
                                    <div className="form-control-wrap">
                                        <a onClick={e => this.changePasswordFieldType()} className="form-icon form-icon-right passcode-switch lg hand" data-target="password">
                                            <em className={"passcode-icon icon-" + (this.state.passwordFieldType == "password" ? "show" : "hide") + " icon ni ni-eye"}></em>
                                            <em className={"passcode-icon icon-" + (this.state.passwordFieldType == "password" ? "hide" : "show") + " icon ni ni-eye-off"}></em>
                                        </a>
                                    <input tabIndex="2" defaultValue={this.state.password} onChange={e => this.state.password = e.target.value} type={this.state.passwordFieldType} className="form-control form-control-lg" id="password" placeholder="کلمه عبور" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-lg btn-primary btn-block">ورود</button>
                                </div>
                            </form>
                            <div className="form-note-s2 text-center pt-4"> حساب کاربری ندارید؟ <Link to="/register/">عضویت و ایجاد حساب</Link></div>
                        </div>
                    </div>
            </div>
            <AuthFooter />
            </div>
    }
    render() {
        let contents = this.state.loading ? this.renderLoading() : this.renderForm();

        return (
            <div>
                {this.state.lockScreen === 1 && this.lockScreen()}
                {contents}
            </div>
        );
    }
}