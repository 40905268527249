import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';

export default class Base extends Component {
    constructor(props) {
        super(props);
    }
    FixedPointNumber(e,f=2) {
        if (e == null || e == undefined || e == "") return 0;
        return parseFloat(e.toFixed(f))
    }
    getCurrentFormId() {
        var id = this.state.id; 
        if (id == 1) {
            var urlParts = window.location.href.split("/");
            if (window.location.href.endsWith("/"))
                id = parseInt(urlParts[urlParts.length - 2]);
            else
                id = parseInt(urlParts[urlParts.length - 1]); 
        }
        return id;
    }
    isEmpty(i) {
        if (i == undefined || i == null || i == "") return true;
        if (i.length <= 0) return true;
        return false;
    }
    isBlank(i) {
        if (i == undefined || i == null || i == "") return true;
        return false;
    }
    isNull(i) {
        if (i == undefined || i == null) return true;
        return false;
    }
    getGlobalContainer() {
        return [
                <div className="modal fade" tabindex="-1" id="modalConfirmRemove" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body modal-body-lg text-center">
                                <div className="nk-modal">
                                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                                    <h4 className="nk-modal-title">هشدار حذف اطلاعات</h4>
                                    <div className="nk-modal-text">
                                        <p className="lead">آیا از حذف آیتم انتخابی مطمئن هستید؟</p>
                                        <p className="text-soft">توجه داشته باشید که در صورت حذف اطلاعات دیگر امکان بازیابی اطلاعات وجود ندارد</p>
                                    </div>
                                    <div className="nk-modal-action mt-5">
                                    <a className="btn btn-lg btn-mw btn-danger hand" data-bs-dismiss="modal" onClick={e => { this.remove(); }} style={{"margin-left": "25px"}}>بله - حذف شود</a>
                                        <a className="btn btn-lg btn-mw btn-light hand" data-bs-dismiss="modal" onClick={e => { this.setState({ id_to_remove: null }); }}>خیر - برگشت</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        ];
    }
    showErorrMessage(msg) {
        toast.error(msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
    showSuccessMessage(msg) {
        toast.success(msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
    httpPost(url, body = {}) {
        this.setState({ lockScreen: 1 });
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.setState({ lockScreen: 0 });
                resolve(data);
            })
            .catch(err => {
                this.setState({ lockScreen: 0 });
                this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
            resolve(null);
            });
        });
    }
    renderLoading() {
        return <div className="lockBox">
            <div className="preview-item">
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span>لطفا کمی صبر نمایید ...</span>
                </button>
            </div>
        </div>
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    lockScreen() {
        return <div className="lockBox">
                    <div className="preview-item">
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span>لطفا کمی صبر نمایید ...</span>
                        </button>
                    </div>
                </div>
    }
    money(value) {
        var number = typeof value === "number" ? value.toString() : value;
        var separator = ',';
        if (number === null || number === undefined)
            return "";
        var s = number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
        return s;
    }
    logOut() {
        this.httpPost("api/v1/logout")
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                window.location.href = "/login"
            }
            else {
                this.showErorrMessage(data.message);
            }
        });
    }

    changeCompany(id) {
        this.httpPost("api/v1/common/switchcompany", { id })
            .then(data => {
                if (data.status === -1) window.location.href = "/login";
                if (data.status === 1) {
                    window.location.href = "/"
                }
                else {
                    this.showErorrMessage(data.message);
                }
            });
    }
    renderNoCompanyRegister() {
        return <div className="card-inner-group">
                    <div className="example-alert">
                        <div className="alert alert-pro alert-warning">
                            <div className="alert-text">
                                <h6>توجه</h6>
                                <p>شما هنوز هیچ شرکتی برای خود تعریف نکرده اید. جهت دسترسی به این بخش بایستی ابتدا یک شرکت تعریف نمایید</p>
                                <p><NavLink className="btn btn-primary" to="/company/new/">افزودن شرکت</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
    }
    confirmRemove(e) {
        this.state.id_to_remove = e;
    }
    remove() {
        this.httpPost("api/v1/" + this.state.controllerName + "/remove", {
            id: this.state.id_to_remove,
            companyId: this.state.data.global.company.id,
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccessMessage("اطلاعات با موفقیت حذف گردید");
                this.state.id_to_remove = null;
                this.refreshData()
            }
            else
                this.showErorrMessage(data.message);
        })
        .catch(err => {
            this.showErorrMessage("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    async refreshData(e,action="list") {
        if (!this.isNull(e)) e.preventDefault();
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/" + action, {
            page: this.state.page,
            count: this.state.per_page,
            phrase: this.state.phrase,
            filters: this.state.filters
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {

                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                });
            }
        })
    }
    async getPdfs() {
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/prints", {
            phrase: this.state.phrase,
            filters: this.state.filters,
            isPdf: 1
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.downloadPDF(data.bs64);
                this.setState({
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                });
            }
        })
    }
    async getPdf() {
        this.setState({ loading: true });

        this.httpPost("api/v1/" + this.state.controllerName + "/print", {
            id: this.getCurrentFormId(),
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.downloadPDF(data.bs64);
                this.setState({
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                });
            }
        })
    }
    handlePageChange(page) {
        this.state.page = page;
        this.refreshData();
    }
    handlePerRowsChange(newPerPage) {
        this.state.page = 1;
        this.state.per_page = newPerPage;
        this.refreshData();
    }
createPaging() {
    let total_page = Math.ceil(this.state.data.recordsCount / this.state.per_page);
    let pages = [];
    let start_page = Math.max(1, this.state.page - 5);
    let end_page = Math.min(total_page, this.state.page + 6);

    if (end_page > total_page)
        end_page = total_page;

    if (start_page == end_page && start_page == 1) {

    }
    else {
        if (this.state.page > 1)
            pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(this.state.page - 1)}>قبلی</a></li>);

        for (let i = start_page; i <= end_page; i++) {
            pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(i)}>{i}</a></li>);
        }

        if (this.state.page < total_page)
            pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(this.state.page + 1)}>بعدی</a></li>);
    }        
        return      <div className="card-inner">
                        <div className="nk-block-between-md g-3">
                            <div className="g">
                                <ul className="pagination justify-content-center justify-content-md-start">{ pages }</ul>
                            </div>
                            <div className="g">
                                <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                    <div>تعداد در هر صفحه</div>
                                    <div>
                                        <select className="form-select js-select2" data-search="on" data-dropdown="xs center" onChange={e => this.handlePerRowsChange(parseInt(e.target.value))}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="40">40</option>
                                            <option value="60">60</option>
                                            <option value="80">80</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    }
    genRowNumber(index) {
        index++;
        return ((this.state.page - 1) * this.state.per_page) + index;
    }
    downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "report.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}