import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Base from './Base';
import { Header } from './Header';
import { Footer } from './Footer';

export default class Persons extends Base {
    constructor(props) {
        super(props);
        this.state = {
            controllerName: 'person',
            data: null,
            loading: true,
            id_to_remove: null,
            page: 1,
            per_page: 10,
            phrase: ''
        };
    }
    componentDidMount() {
        this.refreshData();
    }
    renderForm() {
        return <div>
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">فهرست اشخاص/مشتریان</h3>
                                    <div className="nk-block-des text-soft">
                                        <p>شما {this.money(this.state.data.recordsCount) } مشتری ثبت شده دارید</p>
                                    </div>
                                </div>
                                <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li>
                                                    <a onClick={e=>this.getPdfs(e)} className="btn btn-white btn-outline-light"><em class="icon ni ni-file-pdf"></em><span>خروجی PDF اطلاعات</span></a>
                                                </li>
                                                <li>
                                                    <NavLink to="/person/upload/" className="btn btn-white btn-outline-light"><em className="icon ni ni-download-cloud"></em><span>ورود اکسل اطلاعات</span></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/person/new/" className="btn btn-primary"><em className="icon ni ni-download-cloud"></em><span>افزودن شخص/مشتری جدید</span></NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="card card-bordered card-stretch">
                                {this.state.data.recordsCount <= 0 ?
                                    <div className="card-inner-group">
                                        <div className="example-alert">
                                            <div className="alert alert-pro alert-warning">
                                                <div className="alert-text">
                                                    <h6>توجه</h6>
                                                    <p>در حال حاضر هیچ شخص/مشتری برای شرکت شما ثبت نشده است</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className="card-inner-group">
                                        <div className="card-inner position-relative card-tools-toggle">
                                            <div className="card-title-group">
                                                <form className="card-tools" mthod="post" onSubmit={e => this.refreshData(e)}>
                                                    <div className="form-inline flex-nowrap gx-3">
                                                        <div className="form-wrap">
                                                            <input type="text" defaultValue={this.state.phrase} onChange={e => this.state.phrase = e.target.value} placeholder="نام یا کد/شناسه ملی شخص" className="form-control" />
                                                        </div>
                                                        <div className="btn-wrap">
                                                            <span className="d-none d-md-block"><button onClick={e => this.refreshData(e)} type="submit" className="btn btn-dim btn-outline-light hand"><em class="icon ni ni-search"></em> جستجو</button></span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col"><span className="sub-text">ردیف</span></div>
                                                    <div className="nk-tb-col"><span className="sub-text">نام</span></div>
                                                    <div className="nk-tb-col tb-col-sm"><span className="sub-text text-center">نوع</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">کد/شناسه ملی</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">شماره موبایل</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">شماره تماس</span></div>
                                                    <div className="nk-tb-col text-end"><span className="sub-text">عملیات</span></div>
                                                </div>
                                                {this.state.data.records.map((record,index) =>
                                                    <div className="nk-tb-item">
                                                        <div className="nk-tb-col">
                                                            <span className="sub-text">{this.genRowNumber(index)}</span>
                                                        </div>
                                                        <div className="nk-tb-col">
                                                            <span className="sub-text">{record.name}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.typeString}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.nationalId}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.mobile}</span>
                                                        </div>
                                                        <div className="nk-tb-col tb-col-md">
                                                            <span className="sub-text text-center">{record.phone}</span>
                                                        </div>                                                    
                                                        <div className="nk-tb-col nk-tb-col-tools">
                                                            <ul className="nk-tb-actions gx-1">
                                                                <li className="nk-tb-action-hidden">
                                                                    <NavLink to={"/person/edit/" + record.id + "/"} className="btn btn-trigger btn-icon" title="مشاهده و ویرایش">
                                                                        <em className="icon ni ni-eye-fill"></em>
                                                                    </NavLink>
                                                                </li>
                                                                <li className="nk-tb-action-hidden">
                                                                    <a className="btn btn-trigger btn-icon hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove" title="حذف رکورد">
                                                                        <em className="icon ni ni-cross-fill-c"></em>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <div className="drodown">
                                                                        <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <ul className="link-list-opt no-bdr">
                                                                                <li><NavLink to={"/person/edit/" + record.id + "/"}><em className="icon ni ni-eye"></em><span>مشاهده و ویرایش</span></NavLink></li>
                                                                                <li><a className="hand" onClick={e => this.confirmRemove(record.id)} data-bs-toggle="modal" data-bs-target="#modalConfirmRemove"><em className="icon ni ni-na"></em><span>حذف رکورد</span></a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { this.createPaging() }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>


                </div>
        
    }
    render() {
        if (!this.isBlank(this.state.redirect)) return <Navigate to={this.state.redirect} />

        let contents = this.state.loading ? this.renderLoading() : 
                    [
                        this.state.lockScreen === 1 && this.lockScreen(),
                        this.getGlobalContainer(),
                        <Header data={this.state.data} />,
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    {this.isEmpty(this.state.data.global.companies) ? this.renderNoCompanyRegister() : this.renderForm()}
                                </div>
                            </div>
                        </div>,
                        <Footer />
                    ];

        return contents;
    }
}