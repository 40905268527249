import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from "./components/Home";
import Persons from "./components/Persons";
import Person from "./components/Person";
import Stuffs from "./components/Stuffs";
import Stuff from "./components/Stuff";
import Invoices from "./components/Invoices";
import Invoice from "./components/Invoice";
import InvoiceView from "./components/InvoiceView";
import Upload from "./components/Upload";
import Login from "./components/Login";
import Register from "./components/Register";
import Remember from "./components/Remember";
import Profile from "./components/Profile";
import Company from "./components/Company";
import Companies from "./components/Companies";
import InquiryEconomicCode from "./components/InquiryEconomicCode";
import MyPlans from "./components/MyPlans";
import PaymentFailed from "./components/PaymentFailed";
import MyTransactions from "./components/MyTransactions";

import Units from "./components/Managments/Units";
import Unit from "./components/Managments/Unit";
import Plans from "./components/Managments/Plans";
import Plan from "./components/Managments/Plan";
import Users from "./components/Managments/Users";
import User from "./components/Managments/User";
import Transactions from "./components/Managments/Transactions";

export default class App extends Component {
  render() {
    return (
      <Layout>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/remember' element={<Remember />} />
                <Route path='/profile' element={<Profile />} />

                <Route path='/myplans' element={<MyPlans />} />

                <Route path='/invoice/upload' element={<Upload Type={1} />} />
                <Route path='/invoice/new1' element={<Invoice Inp={1} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/new2' element={<Invoice Inp={2} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/new3' element={<Invoice Inp={3} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/new4' element={<Invoice Inp={4} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/new5' element={<Invoice Inp={5} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/new6' element={<Invoice Inp={6} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/new7' element={<Invoice Inp={7} Id={0} Ins={1} IsNew={true} />} />
                <Route path='/invoice/edit/:id' element={<Invoice Inp={0} Id={1} Ins={0} IsNew={false} />} />
                <Route path='/invoice/view/:id' element={<InvoiceView Inp={0} Id={1} Ins={0} IsNew={false} />} />
                <Route path='/invoices' element={<Invoices />} />
                <Route path='/invoice/cancell/:id' element={<Invoice Inp={0} Id={1} Ins={3} IsNew={true} />} />
                <Route path='/invoice/return/:id' element={<Invoice Inp={0} Id={1} Ins={4} IsNew={true} />} />
                <Route path='/invoice/modify/:id' element={<Invoice Inp={0} Id={1} Ins={2} IsNew={true} />} />

                <Route path='/person/upload' element={<Upload Type={2} />} />
                <Route path='/person/new' element={<Person Id={0} />} />
                <Route path='/person/edit/:id' element={<Person Id={1} />} />
                <Route path='/persons' element={<Persons />} />

                <Route path='/company/new' element={<Company Id={0} />} />
                <Route path='/company/edit/:id' element={<Company Id={1} />} />
                <Route path='/companies' element={<Companies />} />

                <Route path='/stuff/upload' element={<Upload Type={3} />} />
                <Route path='/stuff/new' element={<Stuff Id={0} /> } />
                <Route path='/stuff/edit/:id' element={<Stuff Id={1} />} />
                <Route path='/stuffs' element={<Stuffs />} />
                
                <Route path='/inquiry/economiccode/' element={<InquiryEconomicCode />} />

                <Route path='/mytransactions' element={<MyTransactions />} />

                <Route path='/paymentfailed/' element={<PaymentFailed />} />

                <Route path='/user/new' element={<User Id={0} />} />
                <Route path='/user/edit/:id' element={<User Id={1} />} />
                <Route path='/users' element={<Users />} />

                <Route path='/plan/new' element={<Plan Id={0} />} />
                <Route path='/plan/edit/:id' element={<Plan Id={1} />} />
                <Route path='/plans' element={<Plans />} />

                <Route path='/unit/new' element={<Unit Id={0} />} />
                <Route path='/unit/edit/:id' element={<Unit Id={1} />} />
                <Route path='/units' element={<Units />} />

                <Route path='/transactions' element={<Transactions />} />
        </Routes>
      </Layout>
    );
  }
}